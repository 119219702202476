import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import {
  useLazyDeleteBannerQuery,
  useLazyGetBannerQuery,
  useUpdateBannerMutation,
} from "../../services/banner";
import { showError } from "../../constants/toast";
import { AddBanerRes } from "../../types/General";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "../../components/Pagination";
import Loader from "../../constants/Loader";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";

const ManageBanner = () => {
  const navigate = useNavigate();
  const userData = useAuth();

  //States
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<string>("");
  const[loading,setLoading]=useState(false)
  const [data, setData] = useState<AddBanerRes[]>([]);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  //API Hooks
  const [getBanner, { isLoading }] = useLazyGetBannerQuery();
  const [deleteById] = useLazyDeleteBannerQuery();
  const [updateBanner] = useUpdateBannerMutation();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleClickOpen = () => {
    setOpen(true);
  };

  // API Calling
  const fetchBanner = async () => {
    setLoading(true)
    try {
      const res = await getBanner({
        size: 10,
        search: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (res?.statusCode === 200) {
        const banner = res?.data?.data || [];
        setData(res?.data?.data || []);
        setTotalCount(res?.data?.count);
        if (banner?.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false)
      } else {
        setData([]);
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Banner"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    fetchBanner();
  }, [searchTerm, debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Banner</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isEdit || userData?.role === 1 ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-banner/add-banner")}
                >
                  Add Banner
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Banner Image</TableCell>
                  <TableCell>Banner Title</TableCell>
                  <TableCell>Status </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              {data?.length ? (
                data?.map((item, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>

                      <TableCell align="center">
                        <figure className="bnr_img">
                          <img
                            src={item?.bannerImage}
                            alt="/static/images/user_placeholder.png"
                          />
                        </figure>
                      </TableCell>
                      <TableCell>{item?.title}</TableCell>
                      <TableCell>
                        <Switch
                          checked={!item?.isBlocked}
                          size="small"
                          onChange={() => {
                            updateStatus(
                              item?._id,
                              !item?.isBlocked,
                              updateBanner,
                              fetchBanner
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          {hidePermission?.isEdit || userData?.role === 1 ? (
                            <Tooltip title="edit">
                              <IconButton
                                onClick={() => {
                                  navigate("/manage-banner/edit/" + item?._id);
                                }}
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {hidePermission?.isDelete || userData?.role===1?(

                          <Tooltip title="delete">
                            <IconButton
                            disabled={data?.length===1}
                            style={{ background: data?.length===1 ? 'rgba(0, 0, 0, 0.26)' : undefined }}
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          ):null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                     <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >

                  <ViewCarouselIcon/>  No Banner Found
                  </div>
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={data}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalpages}
      />
      <WarnModal
        name="banner"
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchBanner);
        }}
      />
    </MainContainer>
  );
};

export default ManageBanner;
