import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ChatIcon from "@mui/icons-material/Chat";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, Dispatch, SetStateAction } from "react";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { CommonBody, Loan } from "../../types/General";
import { Permissions } from "../../types/User";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { showError } from "../../constants/toast";
import { LoanAPi } from "../../services/loans";
import Pagination from "../../components/Pagination";
import {
  handleDelete,
  updateStatus,
  updateStatusChat,
} from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import { isValidInput } from "../../utils/validations";

type props = {
  hidePermission: Permissions | null | undefined;
  data: Loan[];
  setData: Dispatch<SetStateAction<Loan[]>>;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  debouncedSearchTerm: string;
  searchTerm: string;
  page: number;
  totalPages: number;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  onPageChange: (newPage: number) => void;
  fetchLoan: () => void;
};

const Pending = ({
  data,
  setData,
  value,
  setValue,
  page,
  totalPages,
  onPageChange,
  fetchLoan,
  searchTerm,
  debouncedSearchTerm,
  setDebouncedSearchTerm,
  setSearchTerm,
}: props) => {
  const navigate = useNavigate();

  const [approvalStatus, setApprovalStatus] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  //API Hooks
  const [deleteById] = LoanAPi.useLazyDeleteLoanQuery();
  const [updateLoan] = LoanAPi.useUpdateLoanMutation();

  const handleApproval = async (event: SelectChangeEvent, id: string) => {
    setApprovalStatus(event.target.value as string);
    const status = event.target.value as string;

    const body = {
      loanStatus: status === "2" ? 2 : 3,
    };
    console.log(body, "bodyyyyyyyyy");

    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    try {
      const res = await updateLoan({
        id: id,
        body: encryptedBody,
      }).unwrap();
      if (res?.statusCode === 200) {
        res?.data?.loanStatus === 2 ? setValue(1) : setValue(2);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div>
      <Box className="cards_header">
        <SearchBar
          searchTerm={searchTerm}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          value={searchTerm}
          onCross={() => setSearchTerm("")}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">User Details</TableCell>

              <TableCell align="center">Car name</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Price</TableCell>

              <TableCell align="center"> Current Status</TableCell>
              <TableCell align="center"> Chat Status</TableCell>

              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          {data?.length ? (
            data?.map((item, index) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {(page - 1) * 10 + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {moment(item?.createdAt).format("DD/MM/YYYY") ||
                      "--/--/----"}
                  </TableCell>
                  <TableCell align="center">
                    <p>{item?.userEmail || "--"}</p>
                    <p>
                      {(item?.userFirstName || "-") +
                        " " +
                        (item?.userLastName || "-")}
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    {item?.vehicleName || "--"}
                  </TableCell>
                  <TableCell align="center">
                    {item?.categoryName || "--"}
                  </TableCell>
                  <TableCell align="center">
                    ${item?.vehiclePrice || "--"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/manage-loans/details/" + item?._id, {
                        state: {
                          step: item?.loanApprovedStage === 1 ? 0 : 1,
                          value: 1,
                          userStatus: "PENDING",
                        },
                      });
                    }}
                  >
                    {/* <Select
                      className="select_div "
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={approvalStatus}
                      onChange={(event) => handleApproval(event, item?._id)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="2">Approve</MenuItem>
                      <MenuItem value="3">Unapproved</MenuItem>
                    </Select> */}

                    {item?.loanApprovedStage === 1
                      ? "Step 1"
                      : item?.loanApprovedStage === 2
                        ? "Step 2"
                        : "-"}
                  </TableCell>
                  <TableCell>
                    <Switch
                      {...label}
                      checked={item?.chatEnabled}
                      size="small"
                      onChange={() => {
                        updateStatusChat(
                          item?._id,
                          !item?.chatEnabled,
                          updateLoan,
                          fetchLoan
                        );
                      }}
                    />
                  </TableCell>

                  <TableCell>
                    <Box className="table_actions">
                      <Tooltip title="view">
                        <IconButton
                          onClick={() =>
                            navigate("/manage-loans/details/" + item?._id, {
                              state: { userStatus: "PENDING" },
                            })
                          }
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Edit">
                        <IconButton
                          onClick={() => navigate("/manage-loans/edit")}
                        >
                          <ModeEditIcon />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title="chat">
                        <IconButton
                          onClick={() =>
                            navigate("/chats", {
                              state: {
                                loanId: item?._id,
                                image: item?.vehicleImage[0],
                                loanNumber: item?.applicationNo,
                                name: item?.vehicleName,
                              },
                            })
                          }
                          disabled={!item?.chatEnabled}
                        >
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="delete">
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(item?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell align="center" colSpan={10} sx={{ color: "#051140" }}>
                No Loan Found
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        module={data}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="loan"
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchLoan);
        }}
      />
    </div>
  );
};

export default Pending;
