import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import {

  CommonBody,
  CriteriaRes,
 
  FactorRes,
  Factorsobj
} from "../types/General";
import { User } from "../types/User";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type QueryParams = {
  page: number;
  size: number;
  search: string;
  userType?:number;
};

export const CreditApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCriteria:builder.query<
    CommonResponseType & {data:CriteriaRes},
    QueryParams
    >({
        query:({page,size,search,userType})=>({
            url:`${END_POINTS.criteria}?userType=${userType}&search=${search}&size=${size}&page=${page}`,
            method:"GET"
        })
    }),
    getCriteriaById:builder.query<
    CommonResponseType & {data:FactorRes},
    {id:string}&QueryParams
    >({
        query:({id,search,size,page})=>({
            url:`${END_POINTS.criteria}/${id}?search=${search}&size=${size}&page=${page}`,
            method:"GET"
        })
    }),
    
    addCriteria:builder.mutation<
    CommonResponseType & {data:Factorsobj},
    {body:CommonBody}
    >({
        query:({body})=>({
            url:`${END_POINTS.criteria}`,
            method:"POST",
            body
            
        })
    }),
    updateCriteria:builder.mutation<
    CommonResponseType & {data:Factorsobj},
    {body:CommonBody,id:string}
    >({
        query:({body,id})=>({
            url:`${END_POINTS.criteria}/${id}`,
            method:"PUT",
            body
            
        })
    }),
    deleteCriteria:builder.query<
    CommonResponseType & {data:Factorsobj},
    {id:string}
    >({
        query:({id})=>({
            url:`${END_POINTS.criteria}/${id}`,
            method:"DELETE",
            
            
        })
    }),

    getCriteriaAdmin:builder.query<
    CommonResponseType & {data:CriteriaRes},
   {}
    >({
        query:({})=>({
            url:`${END_POINTS.criteria}?userType=${1}`,
            method:"GET"
        })
    }),

    getCriteriaUser:builder.query<
    CommonResponseType & {data:CriteriaRes},
   {}
    >({
        query:({})=>({
            url:`${END_POINTS.criteria}?userType=${2}`,
            method:"GET"
        })
    }),

    changeOrder:builder.mutation<
    CommonResponseType & {data:any},{body:any}>({
        query:({body})=>({
            url:`${END_POINTS.sortCriteria}`,
            method:"PUT",
            body
        })
    })

  }),
});

export const {
    useLazyGetCriteriaQuery,
    useLazyGetCriteriaByIdQuery,
    useAddCriteriaMutation,
    useUpdateCriteriaMutation,
    useLazyDeleteCriteriaQuery,
    useLazyGetCriteriaAdminQuery,
    useLazyGetCriteriaUserQuery,
    useChangeOrderMutation
} = CreditApi;
