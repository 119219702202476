import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import PeopleIcon from "@mui/icons-material/People";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { GetCustomer } from "../../types/General";
import {
  ManageCustomerApi,
  useLazyGetCustomerQuery,
} from "../../services/manageCustomers";
import { showError } from "../../constants/toast";
import { isValidInput } from "../../utils/validations";
import {
  handleDelete,
  updateApprovalStatus,
  updateStatus,
} from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
type props = {
  hidePermission: Permissions | null | undefined;
};

const Existing = ({ hidePermission }: props) => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [data, setData] = useState<GetCustomer[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  //   const [value,setValue]=useState<number>(0)
  const [selectedId, setSelectedId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [getUser, { isLoading }] =
    ManageCustomerApi.useLazyGetNewCustomerQuery();
  const [updateCustomer] = ManageCustomerApi.useUpdateCustomerMutation();
  const [deleteById] = ManageCustomerApi.useLazyDeleteCustomerQuery();
  const [exportCSV]=ManageCustomerApi.useLazyExportCSVExistingQuery()
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];
  const fetchUser = async () => {
    setLoading(true);
    try {
      const res = await getUser({
        type: "existing",
        size: 10,
        page: page,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        const len = res?.data?.user || [];
        setData(res?.data?.user || []);
        setTotalCount(res?.data?.count);
        if (len.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false);
      } else {
        setData([]);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };
  const downloadCsv=async()=>{
    try{
      const res=await exportCSV({
        search:debouncedSearchTerm.trim()
      }).unwrap()
      console.log(res,"resss")
      if(res?.statusCode===200){
        window.open(res?.data)
      }

    }catch(error:any){
      showError(error?.data?.message)
    }
  }
  const totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    fetchUser();
  }, [page, debouncedSearchTerm, searchTerm]);
  return (
    <div>
      <Card className="cards">
        <Box className="cards_header">
          {/* <SearchBar /> */}
          <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          />
          <Box className="cards_header_right">
            {/* {hidePermission?.isEdit || userData?.role === 1 ? (
              <Button
                className="btn btn_primary"
                onClick={() => {
                  navigate("/manage-customers/add");
                }}
              >
                Add Customer
              </Button>
            ) : null} */}
            <Button className="btn btn_primary" onClick={()=>{
              downloadCsv()
            }}>
              <FileDownloadIcon /> Export CSV
            </Button>
          </Box>
        </Box>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>Phone No.</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>RIF</TableCell>

              
                <TableCell>Account Status</TableCell>

                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {data?.length ? (
              data?.map((item, index) => (
                <TableBody>
                  <TableRow>
                    <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                    <TableCell>{item?.fullName || "--"}</TableCell>
                    <TableCell>
                      {(item?.countryCode || "") + (item?.phone || "")}
                    </TableCell>
                    <TableCell>{item?.email || ""}</TableCell>
                    <TableCell>{item?.RIF || ""}</TableCell>
                    {/* <TableCell>{item?.role===3?"Business Owner":""}</TableCell> */}
                   
                    <TableCell>
                      <Switch
                        {...label}
                        checked={!item?.isBlocked}
                        size="small"
                        onChange={() => {
                          updateStatus(
                            item?._id,
                            !item?.isBlocked,
                            updateCustomer,
                            fetchUser
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <Tooltip title="Details">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-customers/details/" + item?._id)
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>

                        {hidePermission?.isEdit || userData?.role === 1 ? (
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                navigate("/manage-customers/edit/" + item?._id)
                              }
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}

                        {hidePermission?.isDelete || userData?.role === 1 ? (
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))
            ) : (
              <TableBody>
                <TableCell
                  align="center"
                  colSpan={10}
                  sx={{ color: "#051140" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <PeopleIcon sx={{ marginTop: "5px" }} /> No Customer Found
                  </div>
                </TableCell>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Card>
      <Pagination
        module={data}
        page={page}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
      <WarnModal
        name="customer"
        open={open}
        setOpen={setOpen}
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchUser);
        }}
      />
    </div>
  );
};

export default Existing;
