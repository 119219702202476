import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { settingsApi } from "../../services/settings";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, settings } from "../../types/General";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber, isString } from "../../utils/validations";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../constants/Loader";

const Settings = () => {
  const navigate = useNavigate();
  //states
  const [data, setData] = useState<settings>();
  //API Hooks
  const [getSettings, { isLoading }] = settingsApi.useLazyGetSettingsQuery();
  const [updateSettings]=settingsApi.useUpdateSettingMutation()

  //form-handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      days: data?.minDaysLimit || "",
      down: data?.minDownPayment || "",
      up:data?.maxDownPayment || "",
      creditScore:data?.minCreditScore || ""
    },
    validationSchema: Yup.object({
      days:Yup.string().required("This field is required")
      .min(2,"Minimum 2 characters are needed")
      .max(5,"Maximum 5 characters are allowed"),

      down:Yup.string().required("This field is required")
      .min(2,"Minimum 2 characters are required")
      .max(10,"Maximum 10 characters are allowed"),
      up:Yup.string().required("This field is required")
      .min(2,"Minimum 2 characters are required")
      .max(10,"Maximum 10 characters are allowed"),
      creditScore:Yup.string().required("This field is required")
      .min(2,"Minimum 2 characters are required")
      .max(10,"Maximum 10 characters are allowed")
    }),
    onSubmit: async (values) => {
      const body={
        minDaysLimit:values.days,
        // minDownPayment:values.down,
        // maxDownPayment:values.up,
        minCreditScore:values.creditScore
      }
      let encryptedBody=generateEncryptedKeyBody(body)as CommonBody;

      try{
        const res=await updateSettings({
          body:encryptedBody
        }).unwrap()
        if(res?.statusCode===200){
          showToast("Settings updated successfully")
          fetchSettings()
        }
      }catch(error:any){
        showError(error?.data?.message)
      }
    },
  });

  //API-Call
  const fetchSettings = async () => {
    try {
      const res = await getSettings({}).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data);
      } else {
        setData(undefined);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);
  return (
    <MainContainer>
      <Loader isLoad={isLoading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Settings</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2} className="view_box">
                <Grid item xs={10} className="view_box_list">
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Minimum days limit to re-apply loan
                      </Typography>
                      <TextField
                      className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="days"
                        value={formik.values.days}
                        variant="outlined"
                        fullWidth
                        placeholder="No. of days"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."  
                          ) {
                          } else if (isNumber(val.target.value)   && Number(val.target.value)>0 || val.target.value==="") {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.days && formik.errors.days
                        }
                        inputProps={{
                          maxLength:3
                        }}
                      />
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Minimum Down Payment (%)
                      </Typography>
                      <TextField
                      className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="down"
                        value={formik.values.down}
                        variant="outlined"
                        fullWidth
                        placeholder="Minimum Down Payment"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value) && Number(val.target.value)<100) {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.down && formik.errors.down
                        }
                        inputProps={{
                          maxLength:8
                        }}
                      />
                    </Grid> */}
                    {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Maximum Down Payment (%)
                      </Typography>
                      <TextField
                      className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="up"
                        value={formik.values.up}
                        variant="outlined"
                        fullWidth
                        placeholder="Maximum Down Payment"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)  && Number(val.target.value)<100) {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.up && formik.errors.up
                        }
                        inputProps={{
                          maxLength:8
                        }}
                      />
                    </Grid> */}
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Minimum Credit Score
                      </Typography>
                      <TextField
                      className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="creditScore"
                        value={formik.values.creditScore}
                        variant="outlined"
                        fullWidth
                        placeholder="Minimum Down Payment"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        helperText={
                          formik.touched.creditScore && formik.errors.creditScore
                        }
                        inputProps={{
                          maxLength:8
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Settings;
