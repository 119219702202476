import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { ColorRing } from "react-loader-spinner";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  FormControlLabel,
  Modal,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,

} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useFormik } from "formik";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { UploadMedia } from "../../utils/mediaUpload";
import { showError, showToast } from "../../constants/toast";
import { check, isNumber, isString } from "../../utils/validations";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, Criteria, addCustomerResponse } from "../../types/General";
import { ManageCustomerApi } from "../../services/manageCustomers";
import Loader from "../../constants/Loader";
import { CreditApi } from "../../services/credit";
import { generateResponsiveStyleModal } from "../../utils/ModalStyle";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  //COmments

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
type ScoreType = {
  criteriaId: string;
  rangeId: string;
};

function AddCustomer() {
  const navigate = useNavigate();
  const { id } = useParams();

  //States
  const [profilePhone, setProfilePhone] = useState<string>("+91");
  const [error, setError] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const [image, setImage] = useState<string>("");
  const [data, setData] = useState<addCustomerResponse>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [criterias, setCriterias] = useState<Criteria[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [rifError, setRifError] = useState<boolean>(false);
  //API HOOKS
  const [addUser] = ManageCustomerApi.useAddCustomerMutation();
  const [getCriteriaAdmin] = CreditApi.useLazyGetCriteriaUserQuery();
  const [getById, { isLoading }] =
    ManageCustomerApi.useLazyGetCustomerByIdQuery();
  const [updateCustomer] = ManageCustomerApi.useUpdateCustomerMutation();
  const [load, setLoad] = useState<boolean>(false);
  const [score, setScore] = useState<ScoreType[]>([
    {
      criteriaId: "",
      rangeId: "",
    },
  ]);
  const getEighteenYearsAgoDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split("T")[0];
  };

  const handleScoreChange = (event: any, id: string,parentId:string) => {
    const { value } = event.target;

    setScore((prev) => {
   
      const existingIndex = prev.findIndex(score => score.criteriaId === id);
  
      if (existingIndex !== -1) {
       
        return prev.map((score, index) =>
          index === existingIndex ? { ...score, rangeId: value } : score
        );
      } else {
       
        return [...prev, { criteriaId: id, rangeId: value }];
      }
    });
  };

  console.log(score,"scoresssssssssssssssssssssssss")

  // form-handling

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      fullName: data?.fullName || "",
      email: data?.email || "",
      date: id
        ? moment.utc(data?.dob).format("YYYY-MM-DD")
        : "",

      phone: data?.phone || "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are needed")
        .max(40, "Maximum 40 characters are allowed"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Enter a valid email address"
        ),

      // date: Yup.string().required("This field is required"),
      phone: Yup.string()
        .required("This field is required")
        .matches(/^(?!000000000)\d{10}$/, "Enter a valid phone number").min(7,"Minimum 7 characters are required"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      console.log("hello");
      
      setValue(value + 1);
    },
  });

  //Business form
  const businessForm = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      BusinessName: data?.businessName || "",
      position: data?.position || "",
      email: data?.businessEmail || "",
      website: data?.website || "",

      AddressLine1: data?.addressLine1 || "",
      AddressLine2: data?.addressLine2 || "",
      state: data?.state || "",
      city: data?.city || "",
      landmark: data?.landmark || "",
      zipCode: data?.zipCode || "",
    },
    validationSchema: Yup.object({
      BusinessName: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed"),
      position: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Enter a valid email address"
        ),

      website: Yup.string().required("This field is required").matches(
        /^(?!https?:\/\/)(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
        "The website must be a valid domain"
      ),

      AddressLine1: Yup.string()
        .required("This field is required")
        .min(10, "Minimum 10 characters are required")
        .max(50, "Maximum 50 characters are allowed"),

      state: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed"),
      landmark: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed"),

      city: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed"),
      zipCode: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(10, "Maximum 10 characters are allowed"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setValue(value + 1);
    },
  });

  //Verification form
  const Verification = useFormik({
    enableReinitialize: true,
    initialValues: {
      RIF: data?.RIF || inputValue,
    },
    validationSchema: Yup.object({
      RIF: Yup.string()
        .required("This field is required")
        // .matches(/^[EIJV]-\d{8}-\d$/, "Enter a valid RIF number")
        .matches(/^[EIJV]/, "RIF number can only start with E,I,J,V")
        .matches(
          /-\d{8}-\d$/,
          "RIF number must be in the E/I/J/V-12345678-0 format"
        ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if (
        !formik.values.date ||
        !formik.values.fullName ||
        !formik.values.email ||
        !formik.values.phone
      ) {
        showError("profile setup is incomplete");
        return;
      }
      if (
        !businessForm.values.BusinessName ||
        !businessForm.values.city ||
        !businessForm.values.AddressLine1 ||
        !businessForm.values.email ||
        !businessForm.values.position ||
        !businessForm.values.zipCode ||
        !businessForm.values.website ||
        !businessForm.values.state ||
        !businessForm.values.landmark
      ) {
        showError("Business profile is incomplete");
        return;
      }

      const body = {
        image: image,
        fullName: formik.values.fullName,
        countryCode: (profilePhone.includes("+") ? "" : "+") + profilePhone,
        phone: formik.values.phone,
        email: formik.values.email,
        businessName: businessForm.values.BusinessName,
        businessEmail: businessForm.values.email,
        position: businessForm.values.position,
        website: businessForm.values.website,
        socialSecurityNumber: 1234,
        dob: moment.utc(formik.values.date).toISOString(),
        addressLine1: businessForm.values.AddressLine1,

        addressLine2: businessForm.values.AddressLine2,
        landmark: businessForm.values.landmark,
        city: businessForm.values.city,
        state: businessForm.values.state,
        zipCode: businessForm.values.zipCode,
        RIF: values.RIF,
        role: 3,
      };
      console.log(body, "bodyy");
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (id) {
        try {
          const res = await updateCustomer({
            body: encryptedBody,
            id: id,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Customer updated successfully");
            navigate("/manage-customers");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const res = await addUser({
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Customer added successfully");
            navigate("/manage-customers");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  const areAllCriteriaChecked = () => {
    const criteriaIds = criterias
  .filter(item => item.subcriteria && item.subcriteria.length > 0)
  .map(item => item._id);
    const checkedCriteriaIds = score?.slice(1)?.map(item => item.criteriaId);
    
    console.log(criteriaIds,"dssdd")
    console.log(checkedCriteriaIds,"score dssd")
    // Check if every criteriaId from criterias has a corresponding entry in the score state
    return criteriaIds.every(id => checkedCriteriaIds.includes(id));
  };

  const handleSubmit = async () => {
    setLoading(true);
    

    if (!areAllCriteriaChecked() && !id) {
      showError("Please complete all criteria before submitting.");
      setLoading(false);
      return;
    }
    if (!formik.isValid) {
      showError("profile setup is incomplete");
      setLoading(false);
      return;
    }
    if (!businessForm.isValid) {
      showError("Business profile is incomplete");
      setLoading(false);
      return;
    }

    if(!inputValue){
      setRifError(true)
    }

    if (!/^[EIJV]-\d{8}-\d$/.test(inputValue)) {
      setRifError(true);
      setLoading(false);
      return;
    }
    const newRif = inputValue.replace(/-/g, "");
    const body = {
      image: image,
      fullName: formik.values.fullName,
      countryCode: (profilePhone.includes("+") ? "" : "+") + profilePhone,
      phone: formik.values.phone,
      email: formik.values.email,
      businessName: businessForm.values.BusinessName,
      businessEmail: businessForm.values.email,
      position: businessForm.values.position,
      website: businessForm.values.website,
      socialSecurityNumber: 1234,
      // dob: moment.utc(formik.values.date).toISOString(),
      addressLine1: businessForm.values.AddressLine1,

      addressLine2: businessForm.values.AddressLine2,
      landmark: businessForm.values.landmark,
      city: businessForm.values.city,
      state: businessForm.values.state,
      zipCode: businessForm.values.zipCode,
      RIF: newRif,
      role: 3,
      criteriaArray:score
    };
    console.log(body, "bodyy");
    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

    if (id) {
      try {
        const res = await updateCustomer({
          body: encryptedBody,
          id: id,
        }).unwrap();
        if (res?.statusCode === 200) {
          showToast("Customer updated successfully");
          navigate("/manage-customers");
          setLoading(false);
        }
      } catch (error: any) {
        showError(error?.data?.message);
        setLoading(false);
      }
    } else {
      try {
        const res = await addUser({
          body: encryptedBody,
        }).unwrap();
        if (res?.statusCode === 200) {
          showToast("Customer added successfully");
          navigate("/manage-customers");
          setLoading(false);
        }
      } catch (error: any) {
        showError(error?.data?.message);
        setLoading(false);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setProfilePhone(country?.dialCode);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (
      file &&
      file.type &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      showError("Invalid Image Type ");
      return;
    }
    setLoading(true);

    const res = await UploadMedia(file);

    if (res?.statusCode === 200) {
      setImage(res?.data);
      setLoading(false);
    } else {
      showError(res?.message);
    }
  };

  // API Calls
  const fetchUser = async (id: string) => {
    try {
      const res = await getById({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data || {});
        setProfilePhone(res?.data?.countryCode);
        setImage(res?.data?.image || "");
        const val = res?.data?.RIF;
        const formattedString = val.replace(
          /^([EIJV])(\d{8})(\d)$/,
          "$1-$2-$3"
        );
        console.log(formattedString, "rif........");
        setInputValue(formattedString);

      }
    } catch (error: any) {
      showError(error?.data?.manage);
    }
  };
  const fetchCredit = async () => {
    try {
      const res = await getCriteriaAdmin({}).unwrap();
      if (res?.statusCode === 200) {
        const { data } = res?.data;

        setCriterias(data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUser(id);
    }
  }, []);

  useEffect(() => {
    fetchCredit();
  }, []);

  const handleRif = (event: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(event.key, "keyyy");
    if (inputValue.length === 1 && event.key !== "Backspace") {
      setInputValue(inputValue.charAt(0) + "-" + inputValue.charAt(1));
    }
    if (event.key === "Backspace" && inputValue.length === 2) {
      let value = inputValue;
      const newVal = value.slice(0, -1);

      setInputValue(newVal);
    }
    console.log(event.target);
  };

  //main
  const handleChangeRif = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;
    let start = event.target.selectionStart;
    setRifError(false)
    console.log(start, "start");
    const allowedChars = /^[EIJV]-?[0-9-]*$/i;
    if (!allowedChars.test(newValue)) {
      newValue = newValue.replace(/^[EIJV]-?[0-9-]*$/i, "");
      setRifError(true)
    }

    newValue = newValue.charAt(0).toUpperCase() + newValue.slice(1);

    if (newValue.length === 1) {
      newValue += "-";
    }

    if (
      newValue.length === 11 &&
      inputValue.charAt(10) !== "-" &&
      !inputValue.slice(2).includes("-")
    ) {
      const val = newValue.slice(0, 10) + "-" + newValue.slice(10);
      setInputValue(val);

      return;
    }

    setInputValue(newValue);
  };

  return (
    <div>
      <MainContainer>
        <Loader isLoad={loading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{id ? "Edit Customer" : "Add Customer"}</h1>

            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-customers");
              }}
            >
              Back
            </Button>
          </div>

          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Profile setup" {...a11yProps(0)} />

                  <Tab label="Business details" {...a11yProps(1)} />

                  <Tab label="Verification" {...a11yProps(2)} />
                </Tabs>
              </Box>

              <CardContent sx={{ p: 1 }}>
                {/* Profile Setup  */}
                <form onSubmit={formik.handleSubmit}>
                  <CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className="custom_label">Image</Typography>
                        {image ? (
                          <div className="upload_image_preview">
                            <CardMedia
                              component="img"
                              image={image}
                              alt="photo"
                            />
                            <CancelIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                setImage("");
                                // setFileName("");
                              }}
                            />
                          </div>
                        ) : (
                          <Box
                            className="upload_image"
                            sx={{ marginBottom: "4px" }}
                          >
                            {" "}
                            {loading ? (
                              <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={[
                                  "#a0dfdf",
                                  "#a0dfdf",
                                  "#a0dfdf",
                                  "#a0dfdf",
                                  "#a0dfdf",
                                ]}
                              />
                            ) : (
                              <label htmlFor="icon-button-file">
                                <Input
                                  sx={{ display: "none" }}
                                  id="icon-button-file"
                                  type="file"
                                  inputProps={{
                                    accept: "image/png,image/jpeg",
                                  }}
                                  onChange={handleImageUpload}
                                />

                                <Button
                                  component="span"
                                  className="upload_image_btn"
                                >
                                  <img
                                    src="/static/images/user_placeholder.png"
                                    alt=""
                                  />
                                  <CameraAltIcon />
                                </Button>
                              </label>
                            )}
                          </Box>
                        )}
                        {error && !image ? (
                          <h6 className="err_msg">This field is required</h6>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Full Name
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type={"text"}
                          name="fullName"
                          inputProps={{ maxLength: 40 }}
                          value={formik.values.fullName}
                          variant="outlined"
                          fullWidth
                          placeholder="Full Name"
                          id="fullName"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.fullName && formik.errors.fullName
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Email</Typography>
                        <TextField
                          hiddenLabel
                          type={"email"}
                          name="email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          fullWidth
                          placeholder="Email"
                          id="email"
                          className="text_field"
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Phone</Typography>
                        <PhoneInput
                          enableSearch
                          // value={formik.values.countryCode + formik.values.phone}
                          country={profilePhone}
                          
                          inputClass="phoneInput"
                          inputStyle={{ width: "100%" }}
                          buttonClass="phoneBtn"
                          placeholder="Phone Number"
                          value={profilePhone + formik.values.phone}
                          onChange={(phone, country) =>
                            handleChangePhone(phone, country)
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <h6 className="err_msg">
                            {formik.touched.phone && formik.errors.phone}
                          </h6>
                        ) : (
                          ""
                        )}
                      </Grid>
                      {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Date of Birth
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"date"}
                          name="date"
                          variant="outlined"
                          fullWidth
                          value={formik.values.date || ""}
                          onChange={(val) => {
                            formik.handleChange(val);
                          }}
                          id="date"
                          className="text_field"
                          inputProps={{
                            max: new Date().toISOString().split("T")[0],
                          }}
                          helperText={formik.touched.date && formik.errors.date}
                        />
                      </Grid> */}

                      <Grid item lg={4} md={4} sm={6} xs={12} />
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        // onClick={()=>{
                        //   handleTabChange()

                        // }}
                      >
                        Next
                      </Button>
                    </div>
                  </CustomTabPanel>
                </form>

                {/* Business Details */}
                <form onSubmit={businessForm.handleSubmit}>
                  <CustomTabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Business Name
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type={"text"}
                          name="BusinessName"
                          value={businessForm.values.BusinessName}
                          variant="outlined"
                          fullWidth
                          placeholder="Business Name"
                          id="BusinessName"
                          inputProps={{
                            maxLength: 50,
                          }}
                          onBlur={businessForm.handleBlur}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              businessForm.handleChange(val);
                            }
                          }}
                          helperText={
                            businessForm.touched.BusinessName &&
                            businessForm.errors.BusinessName
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Email</Typography>
                        <TextField
                          hiddenLabel
                          type={"email"}
                          name="email"
                          variant="outlined"
                          value={businessForm.values.email}
                          onChange={businessForm.handleChange}
                          fullWidth
                          placeholder="Email"
                          id="email"
                          className="text_field"
                          onBlur={businessForm.handleBlur}
                          helperText={
                            businessForm.touched.email &&
                            businessForm.errors.email
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Position within the company
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="position"
                          variant="outlined"
                          fullWidth
                          value={businessForm.values.position}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              businessForm.handleChange(val);
                            }
                          }}
                          placeholder="Position"
                          id="position"
                          className="text_field"
                          inputProps={{
                            maxLength: 30,
                          }}
                          helperText={
                            businessForm.touched.position &&
                            businessForm.errors.position
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Website
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="website"
                          variant="outlined"
                          value={businessForm.values.website}
                          fullWidth
                          placeholder="Website Link"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              businessForm.handleChange(val);
                            }
                          }}
                          inputProps={{
                            maxLength: 500,
                          }}
                          id="website"
                          className="text_field"
                          helperText={
                            businessForm.touched.website &&
                            businessForm.errors.website
                          }
                        />
                      </Grid>

                      {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">Business Phone</Typography>
                        <PhoneInput
                          enableSearch
                          // value={formik.values.countryCode + formik.values.phone}
                          country={"us"}
                          inputClass="phoneInput"
                          inputStyle={{ width: "100%" }}
                          buttonClass="phoneBtn"
                          placeholder="Phone Number"
                          value={profilePhone + formik.values.phone}
                          onChange={(phone, country) =>
                            handleChangePhone(phone, country)
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <h6 className="err_msg">
                            {formik.touched.phone && formik.errors.phone}
                          </h6>
                        ) : (
                          ""
                        )}
                      </Grid> */}
                      {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        Business Phone
                      </Typography>
                      <PhoneInput
                        enableSearch
                        country={"us"}
                        inputClass="phoneInput"
                        inputStyle={{ width: "100%" }}
                        buttonClass="phoneBtn"
                        placeholder="Phone Number"
                      />
                    </Grid> */}

                      <Grid item xs={12} className="heading">
                        <Typography className="custom_label" variant="h5">
                          Address
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Address Line 1
                        </Typography>

                        <TextField
                          hiddenLabel
                          type={"text"}
                          value={businessForm.values.AddressLine1}
                          name="AddressLine1"
                          variant="outlined"
                          fullWidth
                          placeholder="Address Line 1 "
                          className="text_field"
                          id="AddressLine1"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "." ||
                              /^-\d+(\.\d+)?$/.test(val.target.value)
                            ) {
                            } else {
                              businessForm.handleChange(val);
                            }
                          }}
                          inputProps={{
                            maxLength: 250,
                          }}
                          helperText={
                            businessForm.touched.AddressLine1 &&
                            businessForm.errors.AddressLine1
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Address Line 2
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="AddressLine2"
                          value={businessForm.values.AddressLine2}
                          variant="outlined"
                          fullWidth
                          placeholder="Address Line 2"
                          className="text_field"
                          id="AddressLine2"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "." ||
                              /^-\d+(\.\d+)?$/.test(val.target.value)
                            ) {
                            } else {
                              businessForm.handleChange(val);
                            }
                          }}
                          inputProps={{
                            maxLength: 250,
                          }}
                          helperText={
                            businessForm.touched.AddressLine2 &&
                            businessForm.errors.AddressLine2
                          }
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">State</Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="state"
                          value={businessForm.values.state}
                          variant="outlined"
                          fullWidth
                          placeholder="State"
                          className="text_field"
                          id="state"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              businessForm.handleChange(val);
                            }
                          }}
                          inputProps={{
                            maxLength: 50,
                          }}
                          helperText={
                            businessForm.touched.state &&
                            businessForm.errors.state
                          }
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">city</Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="city"
                          value={businessForm.values.city}
                          variant="outlined"
                          fullWidth
                          placeholder="City"
                          className="text_field"
                          id="city"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              businessForm.handleChange(val);
                            }
                          }}
                          inputProps={{
                            maxLength: 50,
                          }}
                          helperText={
                            businessForm.touched.city &&
                            businessForm.errors.city
                          }
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Landmark
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="landmark"
                          value={businessForm.values.landmark}
                          variant="outlined"
                          fullWidth
                          placeholder="Landmark"
                          className="text_field"
                          id="landmark"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "." ||
                              /^-\d+(\.\d+)?$/.test(val.target.value)
                            ) {
                            } else {
                              businessForm.handleChange(val);
                            }
                          }}
                          inputProps={{
                            maxLength: 20,
                          }}
                          helperText={
                            businessForm.touched.landmark &&
                            businessForm.errors.landmark
                          }
                        />
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          ZipCode
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="zipCode"
                          value={businessForm.values.zipCode}
                          variant="outlined"
                          fullWidth
                          placeholder="Zip Code"
                          className="text_field"
                          id="zipCode"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isNumber(val.target.value)) {
                              businessForm.handleChange(val);
                            }
                          }}
                          inputProps={{
                            maxLength: 6,
                          }}
                          helperText={
                            businessForm.touched.zipCode &&
                            businessForm.errors.zipCode
                          }
                        />
                      </Grid>

                      <Grid item lg={4} md={4} sm={6} xs={12} />
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        //   onClick={() => setError(true)}
                      >
                        Next
                      </Button>
                    </div>
                  </CustomTabPanel>
                </form>

                {/* Verification  */}

                <CustomTabPanel value={value} index={2}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <Typography className="custom_label">
                        RIF Number
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        type={"text"}
                        name="RIF"
                        value={inputValue}
                        inputProps={{ maxLength: 12 }}
                        variant="outlined"
                        fullWidth
                        placeholder="RIF Number"
                        id="RIF"
                        onKeyDown={handleRif}
                        onChange={handleChangeRif}
                        
                      />
                      {rifError && !/^[EIJV]-\d{8}-\d$/.test(inputValue) ? (
                        <h6 className="err_msg">
                          RIF must be in the format E/I/J/V-12345678-0
                        </h6>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item lg={4} md={4} sm={6} xs={12} />
                  </Grid>
                  <div className="form_btn">
                    <Button
                      size="large"
                      // type="submit"
                      className="btn btn_primary"
                      onClick={()=>{
                        if(id){
                          handleSubmit()
                        }else{
                          if(!inputValue){
                            setRifError(true)

                          }else{

                            setModalOpen(true)
                          }
                        }
                    }}
                      disabled={load}
                      //   onClick={() => setError(true)}
                    >
                      Next
                    </Button>
                  </div>
                </CustomTabPanel>
              </CardContent>
            </Box>
          </Card>

          <Modal
            open={modalOpen}
            onClose={() => {
              setModalOpen((prev) => !prev);
              
            }}
            className="modalStyle"
          >
            <Box sx={generateResponsiveStyleModal}>
              <Grid container spacing={2}>
                
               
                <Grid
                  item
                  xs={12}
                  sx={{
                    maxHeight: "calc(100vh - 200px)",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {criterias?.map((item, index) =>
                    item?.subcriteria?.length ? (
                      <FormControl
                        key={item?._id}
                        sx={{ marginBottom: "16px" }}
                      >
                        <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                          {item?.subcriteria?.length
                            ? item?.criteriaName
                            : null}
                        </FormLabel>
                        <RadioGroup
                          onChange={(e) => handleScoreChange(e, item?._id,item?.criteriaName)}
                        >
                          {item?.subcriteria?.length
                            ? item?.subcriteria?.map((itx, ind) => (
                                <FormControlLabel
                                  key={itx?._id}
                                  value={itx?._id}
                                  control={<Radio />}
                                  label={
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        color: "black",
                                      }}
                                    >
                                      {itx?.range}
                                    </span>
                                  }
                                />
                              ))
                            : null}
                        </RadioGroup>
                      </FormControl>
                    ) : null
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  alignItems={"center"}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    // fullWidth
                    sx={{
                      minWidth: "300px",
                    }}
                    className="btn btn_primary"
                    onClick={() => {
                      handleSubmit()
                      
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </div>
      </MainContainer>
    </div>
  );
}

export default AddCustomer;
