import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Button, Tabs, Tab, Typography } from "@mui/material";
import Accepted from "../../features/manageAdvertisements/Accepted";
import Pending from "../../features/manageAdvertisements/Pending";
import Rejected from "../../features/manageAdvertisements/Rejected";
import { Completed } from "../../features";
import { LoanAPi } from "../../services/loans";
import Loader from "../../constants/Loader";
import { showError } from "../../constants/toast";
import { Permissions } from "../../types/User";
import { Loan } from "../../types/General";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type ItemCount={
  completedLoan: number;
  pendingLoan: number;
  rejected: number;
  active: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  //COmments

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageAdvertisements = () => {
  //states
  const [value, setValue] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [itemCount, setItemCount] = useState<ItemCount>({
    completedLoan: 0,
    pendingLoan: 0,
    rejected: 0,
    active: 0
  });
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [data, setData] = useState<Loan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  //API Hooks
  const [getLoans, { isLoading }] = LoanAPi.useLazyGetLoanQuery();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  //API calls

  const fetchLoan = async () => {
    setLoading(true);
    try {
      const res = await getLoans({
        page: page,
        size: 10,
        search: debouncedSearchTerm.trim(),
        type:
          value === 0
            ? 1
            : value === 1
              ? 2
              : value === 2
                ? 3
                : value === 3
                  ? 4
                  : 1,
      }).unwrap();
      if (res?.statusCode === 200) {
        const len = res?.data?.data || [];
        setData(res?.data?.data || []);
        setTotalCount(res?.data?.count);
        if (len?.length === 0 && page > 1) {
          setPage(page - 1);
        }
        const item = {
          completedLoan: res?.data?.completedLoan,
          pendingLoan: res?.data?.pendingLoan,
          rejected: res?.data?.rejectedLoan,
          active: res?.data?.currentLoan,
        };
        setItemCount(item)
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLoan();
  }, [page, value, searchTerm, debouncedSearchTerm]);
  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Loans</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label={`Pending (${itemCount?.pendingLoan > 100 ? "99+" : itemCount?.pendingLoan})`} {...a11yProps(0)} />
                <Tab label={`Approved (${itemCount?.active > 100 ? "99+" : itemCount?.active})`  } {...a11yProps(1)} />
                <Tab label={`Un-approved (${itemCount?.rejected > 100 ? "99+" : itemCount?.rejected})`} {...a11yProps(2)} />
                <Tab label={`Completed (${itemCount?.completedLoan > 100 ? "99+" : itemCount?.completedLoan})`} {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Pending
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Accepted
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Rejected
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Completed
                hidePermission={hidePermission}
                value={value}
                setValue={setValue}
                setData={setData}
                data={data}
                page={page}
                totalPages={totalPages}
                onPageChange={onPageChange}
                fetchLoan={fetchLoan}
                searchTerm={searchTerm}
                debouncedSearchTerm={debouncedSearchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                setSearchTerm={setSearchTerm}
              />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageAdvertisements;
