import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useAddFaqMutation, useLazyDeleteFaqQuery, useLazyGetFaqQuery } from "../services/faq";
import { showError, showToast } from "../constants/toast";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import WarnModal from "./WarnModal";
import { handleDelete } from "../utils/commonFunctions";
import Loader from "../constants/Loader";

type FAQ={
  id?:string;
  question:string;
  answer:string;
}

const MainFaq = () => {
  const [question, setQuestion] = useState("");
  const [open,setOpen]=useState<boolean>(false)
  const [id,setID]=useState<string>("")
  const [answer, setAnswer] = useState("");
  const [loading,setLoading]=useState(false)
  const[faq,setFaq]=useState<FAQ[]>([
    {
      id:"",
      question:"",
      answer:""
    }
  ])
  const faqs = [
    {
      // id: 1,
      question: "ques1",
      answer: "ans1",
    },
    
  ];

  //API Hooks
  const [addFaqAPi]=useAddFaqMutation()
  const [getFaq]=useLazyGetFaqQuery()
  const [deleteById]=useLazyDeleteFaqQuery()

  //API -calling

  const addFaq=async()=>{

    const body={
      question:question,
      answer:answer
    }
    let encryptedBody=generateEncryptedKeyBody(body)as CommonBody;


    try{
      const res=await addFaqAPi({
        body:encryptedBody
      }).unwrap()
      if(res?.statusCode===200){
        showToast("FAQ added")
        fetchFaq()
      }
      
    }catch(error:any){
      showError(error?.data?.message)
    }
  }

  const fetchFaq=async()=>{
    setLoading(true)
    try{
      const res=await getFaq({}).unwrap()
      if(res?.statusCode===200){
        const data=res?.data?.data?.map((item)=>{
          return{
            id:item?._id,
            question:item?.question,
            answer:item?.answer
          }
        })
        setFaq([...data])
        setLoading(false)
        
      }

    }catch(error:any){
      showError(error?.data?.message)
      setLoading(false)
    }
  }

  const handleChange=(event:React.ChangeEvent<HTMLTextAreaElement>)=>{
    const input=event.target.value
    const wordCount: number = input.split(/\s+/).length;
    if (wordCount > 100) {
      // If word count exceeds 100, split input by spaces and slice the first 100 words
      const truncatedValue = input.split(/\s+/).slice(0, 100).join(' ');
      event.target.value = truncatedValue; // Update input value
      setAnswer(truncatedValue); // Update state
    } else {
      setAnswer(input);
    }
  }



  const handleAdd=()=>{
    addFaq()
    setFaq(prev=>[...prev,{question:question,answer:answer}])
    setAnswer("")
    setQuestion("")
  }
  const handleRemove=(index:number)=>{
    const newData=[...faq]
    newData.splice(index,1)
    setFaq(newData)
  }

  console.log(faq,"fq")

  useEffect(()=>{
    fetchFaq()
  },[])
  return (
    <Grid container spacing={2}>
      <Loader isLoad={loading}/>
      <Grid item xs={12}>
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }}>
            <Typography className="custom_label">Question</Typography>
            <TextField
            className="faq_Answer"
            multiline
            minRows={1}
            maxRows={4}
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={question}
              onChange={(val) =>  {
                if(val.target.value===" "){

                }else{

                  setQuestion(val.target.value)
                }
              }}
              placeholder="Question"
            ></TextField>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography className="custom_label">Answer</Typography>
            <TextField
              className="faq_Answer"
              hiddenLabel
              multiline
              minRows={1}
              maxRows={4}
              type={"text"}
              name="name"
              variant="outlined"
              value={answer}
              onChange={(val) =>  {
                if(val.target.value===" "){

                }else{

                  setAnswer(val.target.value)
                }
              }}
              // onChange={handleChange}
              placeholder="Answer"
              
              
            >

            </TextField>
          </FormControl>
          <Box className="faq_btn">
            <Button
              variant="contained"
              disabled={!question || !answer}
              className="btn btn_primary sm"
              onClick={handleAdd}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Grid>
      {faq?.map((item, index) => {
        return (
          <Grid key={index} item xs={12}>
            <Box className="faq_box">
              <FormControl sx={{ mb: 2, width: "100%" }}>
                <Typography className="custom_label">Question</Typography>
                <TextField
                className="faq_Answer"
                multiline
                minRows={1}
                maxRows={4}
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  value={item?.question}
                  placeholder="Question"
                ></TextField>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Typography className="custom_label">Answer</Typography>
                <TextField
                className="faq_Answer"
                multiline
                minRows={1}
                maxRows={4}
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  value={item?.answer}
                  placeholder="Answer"
                ></TextField>
              </FormControl>
              <Box className="faq_btn">
                <Button variant="contained" className="btn btn_primary sm"  onClick={()=>{
                  // handleRemove(index+1)
                  setID(item?.id || "")
                  setOpen(true)
                }}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Grid>
        );
      })}

      
      <WarnModal
      name="faq" open={open} setOpen={setOpen} handleDelete={()=>{
        handleDelete(deleteById,id,fetchFaq)
      }}/>
    </Grid>
  );
};

export default MainFaq;
