import React, { useState,useEffect } from "react";
import MainContainer from "../../layout/MainContainer";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Typography,
  DialogContent,
  TextField,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageForms = () => {
  const userData=useAuth()
  const [open, setOpen] = useState<boolean>(false);
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);
  const handleClose = () => {
    setOpen(false);
    // formik.setFieldError("message", "");
  };

  

  const navigate = useNavigate();
 
 
  const label = { inputProps: { "aria-label": "Switch demo" } };
 
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Forms"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Forms </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
          
            <Box className="cards_header">
              {/* <SearchBar /> */}
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role===1?(

                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-forms/add")}
                >
                  Add Form
                </Button>
                ):null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Field Name</TableCell>
                    <TableCell> Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow >
                      <TableCell align="center">1</TableCell>
                      <TableCell>New Field</TableCell>
                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-forms/add", {
                                state: { type: "edit" },
                              })
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            
          </Box>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Add Feature</Typography>
          <form>
            <TextField
              hiddenLabel
              className="text_field"
              autoFocus
              id="message"
              type="text"
              placeholder="Feature"
              fullWidth
              
            />

            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageForms;
