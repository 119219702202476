import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manage-customers" element={<Pages.ManageUsers />} />
      <Route path="/manage-customers/add" element={<Pages.AddCustomer />} />
      <Route path="/manage-customers/edit/:id" element={<Pages.AddCustomer />} />
      <Route path="/manage-customers/details/:id" element={<UserDetails />} />
      <Route path="/manage-customers/usersform" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route
        path="/manage-advertisers"
        element={<Pages.ManageServiceProvider />}
      />
      <Route
        path="/manage-advertisers/details"
        element={<Pages.ServiceProviderDetails />}
      />
      <Route
        path="/manage-advertisers/add"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-advertisers/edit"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route path="/manage-categories/edit/:id" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/details"
        element={<Pages.SubCategories />}
      />

      <Route path="/manage-amenities" element={<Pages.ManageAmenities />} />
      <Route
        path="/manage-listed-properties"
        element={<Pages.ManageListedProperties />}
      />
      <Route
        path="/manage-listed-properties/details"
        element={<Pages.PropertyDetails />}
      />
      <Route
        path="/manage-listed-properties/edit"
        element={<Pages.EditProperties />}
      />
      <Route path="/manage-bookings" element={<Pages.ManageBookings />} />
      <Route
        path="/manage-bookings/details"
        element={<Pages.BookingDetails />}
      />
      <Route path="/manage-amenities/add" element={<Pages.AddAmenities />} />
      <Route path="/manage-reviews" element={<Pages.ManageReviews />} />
      <Route path="/manage-reviews/details" element={<Pages.ReviewDetails />} />
      <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
      <Route path="/manage-banner" element={<Pages.ManageBanner />} />
      <Route path="/manage-banner/add-banner" element={<Pages.AddBanner />} />
      <Route path="/manage-banner/edit/:id" element={<Pages.AddBanner />} />
      <Route path="/manage-challenges" element={<Pages.ManageChallenges />} />
      <Route path="/manage-challenges/add" element={<Pages.AddChallenge />} />
      <Route path="/manage-challenges/edit" element={<Pages.AddChallenge />} />
      <Route
        path="/manage-challenges/details"
        element={<Pages.ChallengeDetails />}
      />
      <Route path="/manage-forms" element={<Pages.ManageForms />} />
      <Route path="/manage-forms/add" element={<Pages.AddForm />} />
      <Route path="/manage-cars" element={<Pages.ManageProducts />} />
      <Route path="/manage-cars/details/:id" element={<Pages.ProductDetails />} />
      <Route path="/manage-cars/add" element={<Pages.AddProducts />} />
      <Route path="/manage-cars/edit/:id" element={<Pages.AddProducts />} />
      <Route
        path="/manage-service-stations"
        element={<Pages.ManageServiceStations />}
      />
      <Route
        path="/manage-service-stations/add"
        element={<Pages.AddServiceStation />}
      />
      <Route
        path="/manage-service-stations/edit/:id"
        element={<Pages.AddServiceStation />}
      />
      
      <Route path="/manage-loans" element={<Pages.ManageAdvertisements />} />
      <Route path="/manage-interest" element={<Pages.ManageInterest />} />
      <Route path="/manage-interest/edit" element={<Pages.AddInterest />} />
      <Route path="/manage-loans/edit" element={<Pages.EditLoan />} />
      <Route path="/manage-loans/details/:id" element={<Pages.LoanDetails />} />
      <Route
        path="/manage-testimonials"
        element={<Pages.ManageTestimonials />}
      />
      <Route
        path="/manage-testimonials/add"
        element={<Pages.AddTestimonials />}
      />
      <Route
        path="/manage-testimonials/edit/:id"
        element={<Pages.AddTestimonials />}
      />
      <Route
        path="/manage-testimonials/details/:id"
        element={<Pages.TestimonialDetails />}
      />
      <Route path="/manage-tags" element={<Pages.ManageTags />} />
      <Route path="/settings" element={<Pages.Settings />} />
      <Route path="/chats" element={<Pages.ManageChats />} />
      <Route path="/manage-loan-term" element={<Pages.ManageLoanTerm />} />
      <Route path="/manage-loan-term/add" element={<Pages.AddLoanTerm />} />
      <Route path="/manage-banks" element={<Pages.ManageBanks/>}/>
      <Route path="/manage-banks/add" element={<Pages.AddBankDetails/>}/>

      {/* //Credit Score */}
      <Route path="/manage-credit" element={<Pages.CreditScore/>}></Route>
      <Route path="/manage-credit/factors/:id" element={<Pages.Factors/>}></Route>

      <Route path="/payment-ways" element={<Pages.PaymentWays/>}></Route>
      <Route path="/payment-ways/mode/:id" element={<Pages.Mode/>}></Route>
    </Routes>
  );
};

export default Routing;
