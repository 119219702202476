import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Permissions } from "../../types/User";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { customerSupportApi } from "../../services/customerSupport";
import Loader from "../../constants/Loader";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, QueryData } from "../../types/General";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { isString } from "../../utils/validations";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomerSupport = () => {
  const navigate = useNavigate();
  const userData = useAuth();

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [data, setData] = useState<QueryData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [loading,setLoading]=useState(false)
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [id,setId]=useState<string>("")

  //API Hooks
  const [getQueries, { isLoading }] =
    customerSupportApi.useLazyGetQueriesQuery();
  const [deleteById] = customerSupportApi.useLazyDeleteQueryQuery();
  const [revert]=customerSupportApi.useRevertQueryMutation()

  //form-handling

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reply: "",
    },
    validationSchema: Yup.object({
      reply: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(500, "Maximum 500 characters are allowed"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body={
        reply:values.reply
      }
      let encryptedBody=generateEncryptedKeyBody(body)as CommonBody
      try{
        const res=await revert({
          id:id,
          body:encryptedBody
        }).unwrap()
        if(res?.statusCode===200){
          showToast("Reply send successfully")
          formik.resetForm()
          fetchQueries()
          handleClose()
        }
      }catch(error:any){
        showError(error?.data?.message)
      }
    },
  });
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Customer Support"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(
    Name: string,
    Email: string,
    Queries: string,
    Description: string
  ) {
    return { Name, Email, Queries, Description };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "abdulhussian67@gmail.com",
      "Lorem Ipsum",
      " Lorem Ipsum is simply dummy text.."
    ),
  ];

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
    formik.resetForm()
  };

  const totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const fetchQueries = async () => {
    setLoading(true)
    try {
      const res = await getQueries({
        type: value === 0 ? 1 : 2,
      }).unwrap();
      if (res?.statusCode === 200) {
        const len = res?.data?.data;
        setData(res?.data?.data || []);
        setTotalCount(res?.data?.count);
        if (len?.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false)
      } else {
        setData([]);
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  useEffect(() => {
    fetchQueries();
  }, [page, value]);
  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Customer Support</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Queries Received" {...a11yProps(0)} />
              <Tab label="Complaints/Disputes" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">{/* <SearchBar /> */}</Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>

                  {data?.length ? (
                    data?.map((item, index) => (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {(item?.userId?.fullName || "--") }
                              
                              
                          </TableCell>
                          <TableCell align="center">
                            {item?.userId?.email || "---"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.query || ""}
                          </TableCell>
                          <TableCell align="center">
                            {item?.status===1?"Pending":"Resolved" || ""}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              {hidePermission?.isEdit ||
                              userData?.role === 1 ? (
                                <Tooltip title="reply">
                                  <IconButton disabled={item?.status===2}
                                    style={{ background: item?.status === 2 ? 'rgba(0, 0, 0, 0.26)' : undefined }}
                                   onClick={()=>{
                                    handleClickOpen()
                                    setId(item?._id)
                                  }}>
                                    <ReplyIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}

                              {hidePermission?.isDelete ||
                              userData?.role === 1 ? (
                                <Tooltip title="delete">
                                  <IconButton
                                  disabled={item?.status===1}
                                  style={{ background: item?.status === 1 ? 'rgba(0, 0, 0, 0.26)' : undefined }}
                                    onClick={() => {
                                      setSelectedId(item?._id);
                                      setDeleteOpen(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        No Queries Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">{/* <SearchBar /> */}</Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Queries</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>

                  {data?.length ? (
                    data?.map((item, index) => (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">
                            {(page - 1) * 10 + index + 1}
                          </TableCell>
                          <TableCell align="center">
                          {(item?.userId?.fullName || "--") }
                          </TableCell>
                          <TableCell align="center">
                            {item?.userId?.email || "---"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.query || ""}
                          </TableCell>
                          <TableCell align="center">
                            {item?.status===1?"Pending":"Resolved" || ""}
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              {hidePermission?.isEdit ||
                              userData?.role === 1 ? (
                                <Tooltip title="reply">
                                  <IconButton 
                                  disabled={item?.status===2}
                                  style={{ background: item?.status === 2 ? 'rgba(0, 0, 0, 0.26)' : undefined }}
                                   onClick={()=>{
                                    handleClickOpen()
                                    setId(item?._id)
                                  }}>
                                    <ReplyIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}

                              {hidePermission?.isDelete ||
                              userData?.role === 1 ? (
                                <Tooltip title="delete">
                                  <IconButton
                                  disabled={item?.status===1}
                                  style={{ background: item?.status === 1 ? 'rgba(0, 0, 0, 0.26)' : undefined }}
                                    onClick={() => {
                                      setSelectedId(item?._id);
                                      setDeleteOpen(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  ) : (
                    <TableBody>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        No Complaints/dispute Found
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
        <Pagination
          module={data}
          page={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
        <WarnModal
          name={value === 0 ? "query" : "complaint"}
          open={deleteOpen}
          setOpen={setDeleteOpen}
          handleDelete={() => {
            handleDelete(deleteById, selectedId, fetchQueries);
          }}
        />
      </div>

      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Typography className="dialog_title">Revert Query</Typography>
            <FormControl fullWidth>
              <TextField
                className="text_field"
                multiline
                minRows={1}
                maxRows={4}
                hiddenLabel
                autoFocus
                id="reply"
                type="text"
                placeholder="Revert Query"
                fullWidth
                value={formik.values.reply}
                onBlur={formik.handleBlur}
                onChange={(val)=>{
                  if (val.target.value === "." || val.target.value === " ") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.reply && formik.errors.reply}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">Send</Button>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </MainContainer>
  );
};

export default CustomerSupport;
