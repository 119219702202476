import React, { useState ,useEffect} from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { serviceStationApi } from "../../services/serviceStation";
import { showError } from "../../constants/toast";
import { serviceStationResponse } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import Loader from "../../constants/Loader";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";

const ManageServiceStations = () => {
  const navigate = useNavigate();
  const userData=useAuth()

  //States
  const [page,setPage]=useState<number>(1)
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);
const [data,setData]=useState<serviceStationResponse[]>([])
const [totalCount, setTotalCount] = useState<number>(0);
const [selectedId, setSelectedId] = useState<string>("");
const [open,setOpen]=useState<boolean>(false)
const [loading,setLoading]=useState(false)
//API Hooks
const [getStations,{isLoading}]=serviceStationApi.useLazyGetStationQuery()
const [updateStation]=serviceStationApi.useUpdateServiceStationMutation()
const [deleteById]=serviceStationApi.useLazyDeleteStationQuery()




  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Service Station"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  const fetchStations=async()=>{
    setLoading(true)
    try{
      const res=await getStations({
        size:10,
        page:page,
        search:debouncedSearchTerm.trim()
      }).unwrap()
      if(res?.statusCode===200){
        const len=res?.data?.data || []
        setData(res?.data?.data || [])
        setTotalCount(res?.data?.count)
        if(len?.length===0 && page>1){
          setPage(page-1)
        }
        setLoading(false)
      }else{
        setData([])
        setLoading(false)
      }
    }catch(error:any){
      showError(error?.data?.message)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  useEffect(()=>{
    fetchStations()
  },[searchTerm,debouncedSearchTerm,page])
  return (
    <MainContainer>
      <Loader isLoad={loading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Service Station</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role===1?(

                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-service-stations/add")}
                >
                  Add Service Station
                </Button>
                ):null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell> Image</TableCell>
                    <TableCell> Name</TableCell>
                    <TableCell> Phone number</TableCell>
                    <TableCell> Address</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {data?.length?(
                  data?.map((item,index)=>(
                    <TableBody>
                      <TableRow>
                      <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                      <TableCell>
                        <figure className="bnr_img">
                          <img src={item?.image} alt="/static/images/post.png" />
                        </figure>
                      </TableCell>
                      <TableCell>{item?.name || "--"}</TableCell>
                      <TableCell>+{(item?.countryCode|| "")+"-"+(item?.phone || "")}</TableCell>
                      <TableCell>{item?.address || ""}</TableCell>
                      <TableCell>
                        <Switch {...label} checked={!item?.isBlocked} size="small"  onChange={()=>{
                          updateStatus(
                            item?._id,
                            !item.isBlocked,
                            updateStation,
                            fetchStations
                          )
                        }}/>
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">

                          {hidePermission?.isEdit || userData?.role===1?(

                          <Tooltip title="edit">

                          <IconButton
                            onClick={() =>
                              navigate("/manage-service-stations/edit/"+item?._id, {
                                state: { type: "edit" },
                              })
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          </Tooltip>
                          ):null}
                          {hidePermission?.isDelete || userData?.role===1?(

                          <Tooltip title="delete">

                          <IconButton onClick={()=>{
                            setSelectedId(item?._id)
                            setOpen(true)
                            
                          }}>
                            <DeleteIcon />
                          </IconButton>
                          </Tooltip>
                          ):null}
                        </Box>
                      </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ):(
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                        <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >

                   <CleaningServicesIcon/>   No station Found
                  </div>
                    </TableCell>
                  </TableBody>
                )}
                
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <WarnModal
      name="station"
      open={open}
      setOpen={setOpen}
      handleDelete={()=>{
        handleDelete(deleteById,selectedId,fetchStations)
      }}/>
      <Pagination
      module={data}
      page={page}
      onPageChange={onPageChange}
      totalPages={totalpages}/>
    </MainContainer>
  );
};

export default ManageServiceStations;
