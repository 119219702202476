import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Category,
  CommonBody,
  InterestRate,
  LoanTenure,
  Product,
  Tags,
} from "../../types/General";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLazyGetAllLoanTermProductQuery } from "../../services/loanTerm";
import { showError, showToast } from "../../constants/toast";
import { useLazyGetALLInterestProductQuery } from "../../services/interestRate";
import { useLazyGetAllTagsProductQuery } from "../../services/tags";
import { useLazyGetAllCategoryProductQuery } from "../../services/categories";
import { isNumber, isNumberPrice, isString } from "../../utils/validations";
import {
  useAddCarsMutation,
  useLazyGetCarsByIdQuery,
  useUpdateVehicleMutation,
} from "../../services/manageCars";
import { UploadMedia } from "../../utils/mediaUpload";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../constants/Loader";
type LoanTenureItem = {
  loanTenure: string;
  loanRate: string;
  loanTenureId:string;
  loanRateId:string;
};
type LoanTenureConvert = {
  loanTenure: number;
  loanRate: number;
  loanTenureId:string;
  loanRateId:string;
  
};

type LoanDetail = {
  _id: string;
  loanTenure: number;
  loanRate: number;
  loanTenureId:string;
  loanRateId:string;
};

type CarResponse = {
  name: string;
  image: string[];
  price: number;
  model: string;
  year: string;
  brand: string;
  isDeleted: boolean;
  isBlocked: boolean;
  typeName: string;
  categoryName: string;
  guaranteePeriod: number;
  categoryId: string;
  typeId: string;
  _id: string;
  loanDetails: LoanDetail[];
  createdAt: string;
  updatedAt: string;
  __v: number;
};

const AddProducts = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { id } = useParams();

  //States
  const [loading,setLoading]=useState<boolean>(false)
  const [categoryId, setCategoryID] = useState<string>("");
  const selectRef = useRef<HTMLDivElement>(null);
  const [tenure, setTenure] = useState<LoanTenure[]>([]);
  const [rate, setRate] = useState<InterestRate[]>([]);
  const [tags, setTags] = useState<Tags[]>([]);
  const [category, setCategory] = useState<Category[]>([]);
const [view,setView]=useState<boolean>(false)
  const [loan, setLoan] = useState<string>("");
  const [tag, setTag] = useState<string>("");
  const [loanTenureId,setloanTenureId]=useState<string>("")
  const [loanRateId,setloanRateId]=useState<string>("")
  const [interest, setInterest] = useState<string>("");
  const [loanTenure, setLoanTenure] = useState<LoanTenureItem[]>([
    {
      loanTenure: "",
      loanRate: "",
      loanTenureId:"",
      loanRateId:""
    },
  ]);
  const [index, setIndex] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [err, setErr] = useState<boolean>(false);
  const [data, setData] = useState<Product>();
  const [myImages, setMyImages] = useState<string[]>([]);

  const [show, setShow] = useState<boolean>(false);

  //Api-hooks

  const [getAllTenure] = useLazyGetAllLoanTermProductQuery();
  const [getAllRate] = useLazyGetALLInterestProductQuery();
  const [getAllTags] = useLazyGetAllTagsProductQuery();
  const [getAllCategory] = useLazyGetAllCategoryProductQuery();
  const [addCars] = useAddCarsMutation();
  const [getCarsById] = useLazyGetCarsByIdQuery();
  const [updateVehicle] = useUpdateVehicleMutation();

  //Form handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      model: data?.model || "",
      price: data?.price || "",
      period: data?.guaranteePeriod || "",
      tax:data?.tax || "",
      documentCost:data?.documentCost || "",
      insurance:data?.insuranceFee || "",
      minDownPayment:data?.minDownPayment || "",
      maxDownPayment:data?.maxDownPayment || ""
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are allowed"),
      model: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(16, "Maximum 16 characters are allowed"),
      price: Yup.string().required("This field is Required"),
      period: Yup.string().required("This field is required"),
      tax: Yup.string().required("This field is required"),
      documentCost: Yup.string().required("This field is required"),
      insurance: Yup.string().required("This field is required"),
      minDownPayment:Yup.string().required("This field is required"),
      maxDownPayment:Yup.string().required("This field is required"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      console.log("hello");

      if (!tag) {
        setError(true);
        console.log("one");
        return;
      }
      if (!categoryId) {
        setError(true);
        console.log("two");
        return;
      }
      if (!myImages.length) {
        // setError(true)
        console.log("Three");
        showError("Image is required");
        return;
      }

      if (loanTenure?.length === 0) {
        console.log(loanTenure?.length, "len");
        showError("Please select interest rate for loan tenure");
      }

      const empty = tenure.filter(
        (item) =>
          !loanTenure.some((obj) => Number(obj?.loanTenure) === item?.tenure)
      );
      console.log(empty, "em");

      if (empty.length > 0 && state.type === "add") {
        showError("Select interest rate for all tenure");
        return;
      }

      const body = {
        name: formik.values.name,
        price: Number(formik.values.price),
        model: formik.values.model,
        guaranteePeriod: Number(formik.values.period),
        typeId: categoryId,
        categoryId: tag,
        image: myImages,
        loanDetails: convertLoanRatesToNumber(loanTenure?.slice(1)),
        tax:formik.values.tax,
        documentCost:formik.values.documentCost,
        insuranceFee:formik.values.insurance,
        minDownPayment:formik.values.minDownPayment, 
        maxDownPayment:formik.values.maxDownPayment
      };
      console.log(body, "body");
      let enncryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (id) {
        try {
          const res = await updateVehicle({
            body: enncryptedBody,
            id: id,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Car Updated successfully");
            navigate("/manage-cars");
          }
          console.log(res, "response");
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const res = await addCars({
            body: enncryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Car added successfully");
            navigate("/manage-cars");
          }
          console.log(res, "response");
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  // Methods

  const convertLoanRatesToNumber = (
    items: LoanTenureItem[]
  ): LoanTenureConvert[] => {
    return items.map((item) => ({
      loanTenure: Number(item?.loanTenure),
      loanRate: Number(item.loanRate) || 0, 
      loanRateId:item?.loanRateId,
      loanTenureId:item?.loanTenureId
    }));
  };
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategoryID(event.target.value as string);
  };
  const handleLoanChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setErr(false);
    setLoan(event.target.value as string);
    const data:any=tenure?.filter(item=>item?.tenure==Number(event.target.value))
    console.log(data,"data")
    setloanTenureId(data[0]?._id)
  };
  const handleInterestChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    setErr(false);
    console.log("hello")
    setInterest(event.target.value as string);
    const data:any=rate?.filter(item=>item?.rate==Number(event.target.value))
    console.log(data,".mn")
    setloanRateId(data[0]?._id)
    console.log(loanRateId,"....b")
    if (id) {
      const newData = [...loanTenure];
      newData[index + 1] = {
        ...newData[index + 1],
        loanRate: value,
        loanRateId:loanRateId
      };
      setLoanTenure([...newData]);
    }
  };
  const handleAdd = () => {
    if (!loan || !interest) {
      setErr(true);
      return;
    }
    setShow(true);
    // const newArr=tenure.filter(item=>item?.)
    console.log(loan, "loan");
    console.log(loanRateId,"rate")
    setLoanTenure([...loanTenure, { loanTenure: loan, loanRate: interest ,loanTenureId:loanTenureId,loanRateId:loanRateId}]);
    console.log(loanTenure, "change");
    setLoan("");
    setInterest("");
    setloanRateId("")
    setloanTenureId("")
    setErr(false);
  };
  const handleTagChange = (event: SelectChangeEvent) => {
    setTag(event.target.value as string);
  };

  const ImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log(files);
    if (files) {
      const urls = Array.from(files).map((file) => {
        if (file.type.includes("image")) {
          return URL.createObjectURL(file);
        }
        return null;
      });
      const filteredUrls = urls.filter((url) => url !== null);
      // setImage((prev:any) => [...prev, ...filteredUrls]);
    }
  };

  const handleCrossClick = async (e: any, i: number) => {
    e.stopPropagation();
    console.log(i);
    // if (i >= uploadedImagesCount) {
    //   let arr1 = [...imageObjects];
    //   arr1?.splice(i - uploadedImagesCount, 1);
    //   setImageObjects([...arr1]);
    // } else {
    //   setUploadedImagesCount(uploadedImagesCount - 1);
    // }
    let arr = [...myImages];
    arr?.splice(i, 1);
    setMyImages([...arr]);
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    const allowedLength=Array.from(files || []).length
    if(Array.from(files || []).length>30){
      showError("Max limit is upto 30 images")
      return;
    }

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const invalidFiles = Array.from(files || []).filter(
      (file) => !allowedTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      showError("Inavlid image type");
      return;
    }

    if(myImages.length + allowedLength>30){
      showError("Max limit is upto 30 images")
      return;
    }

    const resArray = await Promise.all(
      Array.from(files || []).map((file) => UploadMedia(file))
    );
    resArray.map((res) => {
      if (res?.statusCode === 200) {
        const uploadedImages = res?.data;
        console.log(uploadedImages);
        setMyImages((prevImages: any) => [...prevImages, uploadedImages]);
      } else {
        showError(res?.message);
      }
    });
  };

  const buttontext=view?"Show less":"View all";

  console.log(loanTenure,"10")

  // API calling

  const fetchCars = async (id: string) => {
    setLoading(true)
    try {
      const res = await getCarsById({
        id: id,
      }).unwrap();
      console.log(res, "response");
      if (res?.statusCode === 200) {
        setData(res?.data || {});
        setTag(res?.data?.categoryId);
        setCategoryID(res?.data?.typeId);
        // setTag(res?.data?.)

        setMyImages(res?.data?.image);

        const newData = res?.data?.loanDetails.map((item) => {
          return {
            loanTenure: String(item?.loanTenure),
            loanRate: String(item?.loanRate),
            loanRateId:item?.loanRateId,
            loanTenureId:item?.loanTenureId
          };
        });
        if (newData) {
          setShow(true);
          setLoanTenure((prev) => [...prev, ...newData]);
          // setLoanTenure(newData)
        }
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };

  const fetchLoanTerm = async () => {
    try {
      const res = await getAllTenure({}).unwrap();
      if (res?.statusCode === 200) {
        setTenure(res?.data?.loanTenure || []);
      } else {
        setTenure([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchInterest = async () => {
    try {
      const res = await getAllRate({}).unwrap();
      if (res?.statusCode === 200) {
        setRate(res?.data?.interestRates || []);
      } else {
        setRate([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchTags = async () => {
    try {
      const res = await getAllTags({}).unwrap();
      if (res?.statusCode === 200) {
        setTags(res?.data?.category || []);
      } else {
        setTags([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchCategory = async () => {
    try {
      const res = await getAllCategory({}).unwrap();
      if (res?.statusCode === 200) {
        setCategory(res?.data?.data || []);
      } else {
        setCategory([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const deleteTenure=(index:number)=>{
    console.log(index,"loanTenure")
    const data=[...loanTenure]
    data?.splice(index+1,1)
    setLoanTenure([...data])
  }


  useEffect(() => {
    fetchLoanTerm();
    fetchInterest();
    fetchTags();
    fetchCategory();
    if (id) {
      fetchCars(id);
    }
  }, []);

console.log(loanTenure,"loanTenure")

  return (
    <>
      <MainContainer>
      <Loader isLoad={loading}/>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{state?.type === "edit" ? "Edit" : "Add"} Car</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-cars");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    <ul className="prdct_images">
                      {myImages?.length > 30 ? null : (
                        <label htmlFor="icon-button-files">
                          <div className="uploader">
                            <input
                              style={{ display: "none" }}
                              id="icon-button-files"
                              type="file"
                              onChange={handleImageUpload}
                              accept=".png, .jpg, .jpeg"
                              multiple
                              
                            />
                            <AddIcon />
                            <h3>Add Product Image</h3>
                          </div>
                        </label>
                      )}
                      {myImages?.length < 5 ? (
                        <>
                          {myImages?.map((item, i) => {
                            return (
                              <li key={i}>
                                <ClearIcon
                                  className="cross"
                                  onClick={(e) => handleCrossClick(e, i)}
                                />
                                <figure>
                                  <img src={item} alt="upload" />
                                </figure>
                              </li>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {myImages?.slice(0, view?myImages.length:5).map((item, i) => {
                            return (
                              <li key={i}>
                                <ClearIcon
                                  className="cross"
                                  onClick={(e) => handleCrossClick(e, i)}
                                />
                                <figure>
                                  <img src={item} alt="upload" />
                                </figure>
                              </li>
                            );
                          })}
                          <Button 
                          className="btn btn_primary"
                          onClick={()=>{
                            setView(!view)
                          }}
                          >
                            {buttontext}
                          </Button>
                        </>
                      )}
                    </ul>
                    {/* {error && myImages.length===0 ? (
                      <h6 className="err_msg">
                        Image cannot be empty
                      </h6>
                    ) : (
                      ""
                    )} */}
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">Car Name</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      value={formik.values.name}
                      variant="outlined"
                      fullWidth
                      onBlur={formik.handleBlur}
                      placeholder="Car Name"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " "
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">Model</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="model"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      value={formik.values.model}
                      fullWidth
                      placeholder="Model"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " " ||
                          /^-\d+(\.\d+)?$/.test(val.target.value)
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.model && formik.errors.model}
                      inputProps={{
                        maxLength: 20,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Select Category
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={categoryId}
                        onChange={handleCategoryChange}
                        displayEmpty
                        MenuProps={{
                          sx: { maxHeight: "250px" },
                        }}
                        // sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {category?.map((item, index) => (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {error && !categoryId ? (
                      <h6 className="err_msg">You must select a category</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Select Tag
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tag}
                        onChange={handleTagChange}
                        MenuProps={{
                          sx: { maxHeight: "300px" },
                        }}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {tags?.map((item, index) => (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {error && !tag ? (
                      <h6 className="err_msg">You must select a Tag</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">Price</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="price"
                      variant="outlined"
                      fullWidth
                      value={formik.values.price}
                      placeholder="Price"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " "
                        ) {
                        } else if (isNumberPrice(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.price && formik.errors.price}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Warranty Period (Years)
                    </Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"text"}
                      name="period"
                      variant="outlined"
                      value={formik.values.period}
                      fullWidth
                      placeholder="Warranty Period"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " " 
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.period && formik.errors.period}
                      inputProps={{
                        maxLength: 3,
                      }}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Tax
                    </Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"text"}
                      name="tax"
                      variant="outlined"
                      value={formik.values.tax}
                      fullWidth
                      placeholder="Tax"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " " 
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.tax && formik.errors.tax}
                      inputProps={{
                        maxLength: 3,
                      }}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Document Cost
                    </Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"text"}
                      name="documentCost"
                      variant="outlined"
                      value={formik.values.documentCost}
                      fullWidth
                      placeholder=" Document Cost"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " " 
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.documentCost && formik.errors.documentCost}
                      inputProps={{
                        maxLength: 5,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Insurance Cost
                    </Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"text"}
                      name="insurance"
                      variant="outlined"
                      value={formik.values.insurance}
                      fullWidth
                      placeholder="Insurance Cost"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " " 
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.insurance && formik.errors.insurance}
                      inputProps={{
                        maxLength: 5,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Minimum Downpayment
                    </Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"text"}
                      name="minDownPayment"
                      variant="outlined"
                      value={formik.values.minDownPayment}
                      fullWidth
                      placeholder="Minimum Downpayment"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " " 
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.minDownPayment && formik.errors.minDownPayment}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Maximum Downpayment
                    </Typography>
                    <TextField
                      hiddenLabel
                      className="text_field"
                      type={"text"}
                      name="maxDownPayment"
                      variant="outlined"
                      value={formik.values.maxDownPayment}
                      fullWidth
                      placeholder="Maximum Downpayment"
                      onChange={(val) => {
                        if (
                          val.target.value === "." ||
                          val.target.value === " " 
                        ) {
                        } else if (isNumber(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.maxDownPayment && formik.errors.maxDownPayment}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12} /> */}

                  <Grid ref={selectRef} item lg={12} md={12} sm={12} xs={12}>
                    <Box className="heading">
                      <Typography component="h5">Select Loan Tenure</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Select Loan Tenure
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={loan}
                        onChange={handleLoanChange}
                        displayEmpty
                        disabled={id ? true : false}
                        // sx={{ minWidth: "250px" }}
                        MenuProps={{
                          sx: { maxHeight: "250px" },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {tenure?.map((item, index) => (
                          <MenuItem
                            value={item?.tenure}
                            disabled={loanTenure?.some(
                              (obj) => Number(obj.loanTenure) === item?.tenure
                            )}
                          >
                            {item?.tenure} Months
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {err && !loan ? (
                      <h6 className="err_msg">You must select a Loan Tenure</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Select Interest Rate
                      </Typography>
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={interest}
                        onChange={handleInterestChange}
                        displayEmpty
                        MenuProps={{
                          sx: { maxHeight: "250px" },
                        }}
                        // sx={{ minWidth: "250px" }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {rate?.map((item, index) => (
                          <MenuItem value={item?.rate}>
                            {item?.rate}% (p.a)
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {err && !interest ? (
                      <h6 className="err_msg">
                        You must select a Interest Rate
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    {id ? null : (
                      <Button
                        className="btn btn_primary"
                        onClick={() => {
                          handleAdd();
                        }}
                        sx={{ marginTop: "20px" }}
                      >
                        Add
                      </Button>
                    )}
                  </Grid>
                  {show && loanTenure?.length > 1 ? (
                    <>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Loan Tenure
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Interest Rate
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}></Grid>
                      {loanTenure?.slice(1).map((item, index) => (
                        <>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Typography>{item?.loanTenure} Months</Typography>
                          </Grid>
                          <Grid item lg={4} md={4} sm={6} xs={12}>
                            <Typography>{item?.loanRate}% (p.a) </Typography>
                          </Grid>

                          {id ? (
                            <Button
                              className="btn btn_primary"
                              onClick={() => {
                                setLoan(item?.loanTenure);
                                setInterest(item?.loanRate);
                                setIndex(index);
                                // window.scrollTo({ top: selectRef?.current?.offsetTop ,behavior: 'smooth'});
                                selectRef.current?.scrollIntoView({
                                  behavior: "smooth",
                                  block: "center",
                                  inline: "center",
                                });
                              }}
                              sx={{ marginTop: "20px" }}
                            >
                              Edit
                            </Button>
                          ) : (
                            
                              id ? 
                              <Grid item lg={4} md={4} sm={6} xs={12}></Grid> : 
                              (
                                <Button
                                className="btn btn_primary"
                                onClick={() => {
                                  // setLoan(item?.loanTenure);
                                  // setInterest(item?.loanRate);
                                  // setIndex(index);
                                  // // window.scrollTo({ top: selectRef?.current?.offsetTop ,behavior: 'smooth'});
                                  // selectRef.current?.scrollIntoView({
                                  //   behavior: "smooth",
                                  //   block: "center",
                                  //   inline: "center",
                                  // });
                                  deleteTenure(index)
                                }}
                                sx={{ marginTop: "20px" }}
                              >
                               Delete
                              </Button>
                              )
                            
                          )}
                        </>
                      ))}
                    </>
                  ) : null}
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddProducts;
