import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";

// import Dialog from '@mui/material/Dialog';
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  useAddLoanTermMutation,
  useLazyDeleteTermQuery,
  useLazyGetLoanTermQuery,
  useUpdateLoanTermMutation,
} from "../../services/loanTerm";
import Loader from "../../constants/Loader";
import { showError, showToast } from "../../constants/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber, isNumberPrice, isString, isValidInput } from "../../utils/validations";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, LoanTenure } from "../../types/General";
import { updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import TodayIcon from "@mui/icons-material/Today";
const ManageLoanTerm = () => {
  const navigate = useNavigate();
  const userData=useAuth()

  //states
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [data, setData] = useState<LoanTenure[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [deleteTag, setDeleteTag] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [wordId, setWordId] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [loading,setLoading]=useState(false)
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);
  //API Hooks
  const [getLoanTerm, { isLoading }] = useLazyGetLoanTermQuery();
  const [addTerm] = useAddLoanTermMutation();
  const [updateTerm] = useUpdateLoanTermMutation();
  const [deleteById] = useLazyDeleteTermQuery();

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange:false,
    validateOnBlur:false,
    initialValues: {
      tenure: "",
    },
    validationSchema: Yup.object({
      tenure: Yup.string()
        .required("This field is Required")
        .min(1, "Minimum 1 character is required")
        .max(10, "Maximum 10 character allowed"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        tenure: Number(formik.values.tenure),
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (wordId) {
        try {
          const res = await updateTerm({
            id: wordId,
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Loan term updated");
            setOpen(false);
            setWordId("");
            setEdit(false);
            await fetchLoanTerm();
            // formik.setFieldValue("tenure", "");
            formik.resetForm()
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const res = await addTerm({
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Loan term  added");
            setOpen(false);
            await fetchLoanTerm();
            // formik.setFieldValue("tenure", "");
            formik.resetForm()
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  const fetchLoanTerm = async () => {
    setLoading(true)
    try {
      const res = await getLoanTerm({
        size: 10,
        search: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (res?.statusCode === 200) {
        const loanTenureData = res?.data?.loanTenure || [];
        setData(res?.data?.loanTenure || []);

        setTotalCount(res?.data?.count);
        if (loanTenureData.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false)
      } else {
        setData([]);
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteById({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Loan term deleted successfully");
        await fetchLoanTerm();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };

  const handleEdit = (id: string) => {
    setOpen(true);
    setWordId(id);
    // fetchInterestById(id)
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setWordId("");
    formik.setFieldValue("tenure", "");
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Loan Term"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };


  const totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  useEffect(() => {
    fetchLoanTerm();
  }, [searchTerm, debouncedSearchTerm, page]);
  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Loan Term</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role===1?(

                <Button className="btn btn_primary" onClick={handleOpen}>
                  Add Loan Term
                </Button>
                ):null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Loan Term</TableCell>
                    {/* <TableCell>Interest Rate</TableCell> */}
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {data?.length ? (
                  data?.map((item, index) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell>{item?.tenure} months</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            size="small"
                            onChange={() => {
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                updateTerm,
                                fetchLoanTerm
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            {hidePermission?.isEdit || userData?.role===1?(

                            <Tooltip title="Edit">
                              <IconButton
                                onClick={() => {
                                  handleEdit(item?._id);
                                  setEdit(true);
                                  formik.setFieldValue("tenure", item?.tenure);
                                }}
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                            ):null}
                            {hidePermission?.isDelete || userData?.role===1?(

                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setSelectedId(item?._id);
                                  setDeleteTag(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            ):null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                        <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >

                    <TodayIcon/>  No Term Found
                  </div>
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <WarnModal
        name="term"
        open={deleteTag}
        setOpen={setDeleteTag}
        handleDelete={() => {
          handleDelete(selectedId);
        }}
      />
      <Pagination
        module={data}
        onPageChange={onPageChange}
        page={page}
        totalPages={totalPages}
      />
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="custom_label">
            {edit ? "Edit Loan Term" : "Add Loan Term"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                className="text_field"
                hiddenLabel
                autoFocus
                id="tenure"
                type="text"
                name="tenure"
                onBlur={formik.handleBlur}
                value={formik.values.tenure}
                placeholder="Loan Term"
                inputProps={{ maxLength: 3 }}
                fullWidth
                helperText={formik.touched.tenure && formik.errors.tenure}
                onChange={(val) => {
                  if (val.target.value === "." || val.target.value === " ") {
                  } else if (isNumber(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" type="submit">
                Save
              </Button>
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageLoanTerm;
