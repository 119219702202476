import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  DialogContent,
  Dialog,
  Typography,
  TextField,
  FormControl,
  TableBody,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import DeleteIcon from "@mui/icons-material/Delete";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import { isString, isValidInput } from "../../utils/validations";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import Loader from "../../constants/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { paymentWays } from "../../services/paymentWays";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, paymentAPiData } from "../../types/General";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
const label = { inputProps: { "aria-label": "Switch demo" } };

const PaymentWays = () => {
  const userData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [deleteWay, setDeleteWay] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<paymentAPiData[]>([]);
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  //API Hooks
  const [addPaymentWays] = paymentWays.useAddWaysMutation();
  const [updatePaymentWays] = paymentWays.useUpdateWaysMutation();
  const [deleteById] = paymentWays.useLazyDeleteWaysQuery();
  const [getPayementWays, { isLoading }] = paymentWays.useLazyGetWaysQuery();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fieldName: "",
    },

    validationSchema: Yup.object({
      fieldName: Yup.string().required("This field is required"),
    }),

    onSubmit: async (value) => {
      const body = {
        fieldName: value.fieldName,
      };

      console.log(body, "body");
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      try {
        const res = await addPaymentWays({
          body: encryptedBody,
        }).unwrap();
        if (res?.statusCode === 200) {
          showToast("Payment Way Added Successfully");
          handleClose();
          formik.resetForm();
          await getPayement();
        }
      } catch (error: any) {
        showToast(error?.data?.message);
      }
    },
  });

  const getPayement = async () => {
    setLoading(true);
    try {
      const res = await getPayementWays({
        search: debouncedSearchTerm.trim(),
        size: 10,
        page: page,
      }).unwrap();
      if (res?.statusCode === 200) {
        const { count, field } = res?.data;
        const length = field?.length;

        setTotalCount(count);
        setData(field);
        if (length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);

  useEffect(() => {
    getPayement();
  }, [page, debouncedSearchTerm, searchTerm]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Payment Way </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => setOpen((prev) => !prev)}
                  >
                    Add Payment way
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Payment Mode</TableCell>
                    <TableCell>View Mode</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {data?.length ? (
                  data?.map((item, index) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell>{item?.fieldName || "--"}</TableCell>
                        <TableCell align="center">
                          <Button
                            className="btn btn_primary"
                            onClick={() =>
                              navigate("/payment-ways/mode/" + item?._id)
                            }
                          >
                            View Details
                          </Button>
                        </TableCell>

                        <TableCell>
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            size="small"
                            onChange={() => {
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                updatePaymentWays,
                                getPayement
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            {/* {hidePermission?.isEdit || userData?.role === 1 ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => {
                                    handleEdit(item?._id);
                                    setEdit(true);
                                  }}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null} */}
                            {hidePermission?.isDelete ||
                            userData?.role === 1 ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setDeleteWay(true);
                                    setSelectedId(item?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <AddCardIcon /> No Payment Ways Found
                      </div>
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Add Payement Way</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                name="fieldName"
                value={formik.values.fieldName}
                type="text"
                placeholder="Add Payment Way"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.fieldName && formik.errors.fieldName}
                inputProps={{
                  maxLength: 30,
                }}
              />
            </FormControl>

            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <Pagination
        module={data}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalpages}
      />
      <WarnModal
        open={deleteWay}
        setOpen={setDeleteWay}
        name="payment way"
        handleDelete={() => {
          handleDelete(deleteById, selectedId, getPayement);
        }}
      />
    </MainContainer>
  );
};

export default PaymentWays;
