import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";

import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ChatIcon from "@mui/icons-material/Chat";
import TodayIcon from "@mui/icons-material/Today";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import StyleIcon from "@mui/icons-material/Style";
import { SIDEBAR_WIDTH } from "../constants";
import PaymentsIcon from "@mui/icons-material/Payments";

import QuizIcon from "@mui/icons-material/Quiz";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SettingsIcon from "@mui/icons-material/Settings";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PaidIcon from "@mui/icons-material/Paid";
import AddCardIcon from '@mui/icons-material/AddCard';
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { PropsWithoutRef, useEffect, useState ,useRef} from "react";
import useAuth from "../hooks/useAuth";
import { User } from "../types/User";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const userData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedRef=useRef<HTMLDivElement | null>(null)


  // const items=[
  //   {
  //     label:"Dashboard",
  //     icon:<DashboardIcon />
  //   }
  // ]

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Customers",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manage-customers",
    },
    {
      label: "Manage Categories",
      icon: <CategoryIcon />,
      key: "3",
      selected: "/manage-categories",
    },
    {
      label: "Manage Tags",
      icon: <StyleIcon />,
      key: "4",
      selected: "/manage-tags",
    },
    {
      label: "Manage Cars",
      icon: <DirectionsCarIcon />,
      key: "5",
      selected: "/manage-cars",
    },
    {
      label: "Manage Loans",
      icon: <AccountBalanceIcon />,
      key: "6",
      selected: "/manage-loans",
    },
    {
      label: "Credit Score",
      icon: <CreditScoreIcon />,
      key: "7",
      selected: "/manage-credit",
    },
    {
      label: "Manage Interest",
      icon: <PaymentsIcon />,
      key: "8",
      selected: "/manage-interest",
    },
    {
      label: "Chats",
      icon: <ChatIcon />,
      key: "9",
      selected: "/chats",
    },
    {
      label: "Manage Forms",
      icon: <DynamicFormIcon />,
      key: "10",
      selected: "/manage-forms/add",
    },
    {
      label: "Manage Loan Term",
      icon: <TodayIcon />,
      key: "11",
      selected: "/manage-loan-term",
    },
    {
      label: "Manage Service Station",
      icon: <CleaningServicesIcon />,
      key: "12",
      selected: "/manage-service-stations",
    },
    {
      label: "Customer Support",
      icon: <HelpCenterIcon />,
      key: "13",
      selected: "/customer-support",
    },
    {
      label: "Manage Testimonials",
      icon: <ReviewsIcon />,
      key: "14",
      selected: "/manage-testimonials",
    },
    {
      label: "Manage Banner",
      icon: <ViewCarouselIcon />,
      key: "15",
      selected: "/manage-banner",
    },
    {
      label: "Manage Notifications",
      icon: <NotificationsActiveIcon />,
      key: "16",
      selected: "/manage-notifications",
    },
    {
      label: "Reports and Analytics",
      icon: <BarChartIcon />,
      key: "17",
      selected: "/analytics",
    },
    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "18",
      selected: "/manage-subAdmin",
    },
    {
      label: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "19",
      selected: "/cms",
    },
    {
      label: "Manage FAQS",
      icon: <QuizIcon />,
      key: "20",
      selected: "/faq",
    },
    {
      label: "Manage Payment Ways",
      icon: <AddCardIcon />,
      key: "21",
      selected: "/payment-ways",
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      key: "22",
      selected: "/settings",
    },

  ];

  // const [list, setList] = useState<any>(
  //   userData?.role ==1 ? items : userData?.permissions
  // );
  const [list, setList] = useState<any>(
    userData?.role === 1 ? items : userData?.permission
  );
  
  // console.log(userData?.permission,"user")
  // console.log(list,"list")

  useEffect(() => {
    let arr = [];
    if (userData?.role === 1) {
      setList(items);
    } else if (userData?.permission?.length) {
      const permission = userData?.permission;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        console.log(idx,"index")
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      
      
      setList(arr);
    }
    
  }, [userData]);



  useEffect(()=>{
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({
        behavior: "smooth", 
        block: "center", 
        // inline:"center"
        
      });
      
    }
  },[location.pathname])




  // console.log(list, "list");


  return (
<Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo (2).png"
              alt=""
              onClick={()=>{
                if(userData?.role === 1) {
                  navigate("/dashboard");
              }
              }}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List
                    key={item?.label}
                    className="sidebr-lst"
                    sx={{ padding: 0 }}
                  >
                    <ListItemButton 
                    
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                      
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={()=>{
                if(userData?.role === 1) {
                  navigate("/dashboard");
              }
              }}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List
                    key={item?.label}
                    className="sidebr-lst"
                    sx={{ padding: 0 }}
                  >
                    <ListItemButton
                    ref={isSelected?selectedRef:null}
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      
                      // onClick={() => navigate(item?.selected)}
                      onClick={() => {
                        console.log("Clicked item:", item);
                        console.log("Selected path:", item?.selected);
                        
                        navigate(item?.selected);
                    }}
                
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
    </Box>
  );
}
