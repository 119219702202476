import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PanoramaIcon from "@mui/icons-material/Panorama";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { useNavigate } from "react-router-dom";
import { DashboardApi } from "../../services/dashboard";
import { showError } from "../../constants/toast";
import { DashBoardData } from "../../types/General";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>("Monthly");
  const [loanFilter, setLoanFilter] = useState<string>("Monthly");
  const [data,setData]=useState<DashBoardData>()
  const [user, setUser] = useState<string[]>();
  const [userValues,setUserValues]= useState<number[]>([])
  const [loan,setLoan]=useState<string[]>()
  const [loanValues,setLoanValues]=useState<number[]>([])


  //API Hooks
  const[getDashBoard]=DashboardApi.useLazyGetDashBoardQuery()
  const [getUserGraph]=DashboardApi.useLazyGetUserGraphsQuery()
  const [getLoanGraph]=DashboardApi.useLazyGetloanGraphsQuery()


  

  const handleGraphChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as string);
  };
  const handleLoanGraphChange = (event: SelectChangeEvent) => {
    setLoanFilter(event.target.value as string);
  };
  const graphOrderData = {
    labels:user,
    datasets: [
      {
        label: "Users",
        data: userValues,
        borderColor: " #a0dfdf",
        backgroundColor: "#a0dfdf",
      },
    ],
  };
  const graphRevenueData = {
    labels: loan,
    datasets: [
      {
        label: "Loan Requests",
        data:loanValues,
        borderColor: " #a0dfdf",
        backgroundColor: " #a0dfdf",
      },
    ],
  };
  const graphDownloadsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Downloads",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };

  const fetchDashBoard=async()=>{
    try{
        const res=await getDashBoard({}).unwrap()
        if(res?.statusCode===200){
          setData(res?.data)
        }else{
          setData(undefined)
        }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }

  const fetchLoanGraphs=async()=>{
    try{
      const res=await getLoanGraph({
          type:loanFilter==="Daily"?"Daily":(loanFilter==="Monthly"?"Monthly":(loanFilter==="Weekly"?"Weekly":(loanFilter==="Yearly"?"Yearly":"")))
      }).unwrap()
      if(res?.statusCode===200){
        const userKeys = Object?.keys(res?.data?.totalLoanRequest) || [];
        console.log(userKeys ,".....f")
        const value=Object?.values(res?.data?.totalLoanRequest) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setLoan(userKeys)
        setLoanValues(values)
      }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }
  const fetchUserGraphs=async()=>{
    try{
      const res=await getUserGraph({
          type:filter==="Daily"?"Daily":(filter==="Monthly"?"Monthly":(filter==="Weekly"?"Weekly":(filter==="Yearly"?"Yearly":"")))
      }).unwrap()
      if(res?.statusCode===200){
        const userKeys = Object?.keys(res?.data?.totalUsers) || [];
        console.log(userKeys ,".....f")
        const value=Object?.values(res?.data?.totalUsers) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setUser(userKeys)
        setUserValues(values)
      }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }

  useEffect(()=>{
        fetchDashBoard()
  },[])

  useEffect(()=>{
    fetchUserGraphs()
  },[filter])
  useEffect(()=>{
    fetchLoanGraphs()
  },[loanFilter])

  console.log(userValues,"......")
  

  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-customers",{
              state:{value:0}
            })}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Customer</h3>
              <h4 className="mn_hdng">{data?.totalUser || "0"}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-customers",{
              state:{value:2}
            })}
          >
            <RequestPageIcon className="svg_icn" />
            <div>
              <h3>Total Prequalify Requests</h3>
              <h4 className="mn_hdng">{data?.prequalifyRequest || "0"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-customers",{
              state:{value:1}
            })}
          >
            <PersonAddAltIcon className="svg_icn" />
            <div>
              <h3>New Customers</h3>
              <h4 className="mn_hdng">{data?.newUsersToday || "0"}</h4>
            </div>
          </Item>
        </Grid>
        {/* <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageservices")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>Total Booking Requests</h3>
              <h4 className="mn_hdng">10</h4>
            </div>
          </Item>
        </Grid> */}
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-loans")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>Total Loan Requests</h3>
              <h4 className="mn_hdng">{data?.totalLoanRequest || "0"}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Users
              <FormControl>
                <Select
                  className="select_div"
                  value={filter}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleGraphChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Total Loan Requests
              <FormControl>
                <Select
                  className="select_div"
                  value={loanFilter}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={handleLoanGraphChange}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="Daily">Daily</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                  <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
        {/* <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Downloads</h2>
            <LineChart data={graphDownloadsData} />
          </Item>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default DashBoard;
