import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Select,
  Menu,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Modal,
} from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DashboardApi } from "../../services/dashboard";
import { showError } from "../../constants/toast";
// import Modal from "@mui/material/Modal";
import { generateResponsiveStyle } from "../../utils/ModalStyle";

const Analytics = () => {
  const [filter, setFilter] = useState<string>("Monthly");
  const [loanFilter, setLoanFilter] = useState<string>("Monthly");
  const [user, setUser] = useState<string[]>();
  const [userValues, setUserValues] = useState<number[]>([]);
  const [loan, setLoan] = useState<string[]>();
  const [loanValues, setLoanValues] = useState<number[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [getUserGraph] = DashboardApi.useLazyGetUserGraphsQuery();
  const [getLoanGraph] = DashboardApi.useLazyGetloanGraphsQuery();
  const [getAllGraphs] = DashboardApi.useLazyGetAllGraphsQuery();
  
  const handleGraphChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as string);
  };
  const handleLoanGraphChange = (event: SelectChangeEvent) => {
    setLoanFilter(event.target.value as string);
  };
  const graphRevenueData = {
    labels: loan,
    datasets: [
      {
        label: "Loan Requests",
        data: loanValues,
        borderColor: " #a0dfdf",
        backgroundColor: " #a0dfdf",
      },
    ],
  };

  const downloadCsvUser = async () => {
    try {
      const res = await getAllGraphs({}).unwrap();
      console.log(res, "resss");
      if (res?.statusCode === 200) {
        window.open(res?.data?.userExportUrl);
        setOpen(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const downloadCsvLoan = async () => {
    try {
      const res = await getAllGraphs({}).unwrap();
      console.log(res, "resss");
      if (res?.statusCode === 200) {
        console.log(res?.data,"log")

        window.open(res?.data?.loanRequesExportUrl);
        setOpen(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const graphAdvertisersData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Loan Requests",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: " #a0dfdf",
        backgroundColor: " #a0dfdf",
      },
    ],
  };

  const graphUsersData = {
    labels: user,
    datasets: [
      {
        label: "Users",
        data: userValues,
        borderColor: " #a0dfdf",
        backgroundColor: "#a0dfdf",
      },
    ],
  };
  const handleClose = () => setOpen(false);
  const fetchLoanGraphs = async () => {
    try {
      const res = await getLoanGraph({
        type:
          loanFilter === "Daily"
            ? "Daily"
            : loanFilter === "Monthly"
              ? "Monthly"
              : loanFilter === "Weekly"
                ? "Weekly"
                : loanFilter === "Yearly"
                  ? "Yearly"
                  : "",
      }).unwrap();
      if (res?.statusCode === 200) {
        const userKeys = Object?.keys(res?.data?.totalLoanRequest) || [];
        console.log(userKeys, ".....f");
        const value = Object?.values(res?.data?.totalLoanRequest) || [];
        const values = value.map((val: unknown) =>
          typeof val === "number" ? val : 0
        );
        setLoan(userKeys);
        setLoanValues(values);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const fetchUserGraphs = async () => {
    try {
      const res = await getUserGraph({
        type:
          filter === "Daily"
            ? "Daily"
            : filter === "Monthly"
              ? "Monthly"
              : filter === "Weekly"
                ? "Weekly"
                : filter === "Yearly"
                  ? "Yearly"
                  : "",
      }).unwrap();
      if (res?.statusCode === 200) {
        const userKeys = Object?.keys(res?.data?.totalUsers) || [];
        console.log(userKeys, ".....f");
        const value = Object?.values(res?.data?.totalUsers) || [];
        const values = value.map((val: unknown) =>
          typeof val === "number" ? val : 0
        );
        setUser(userKeys);
        setUserValues(values);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    fetchUserGraphs();
  }, [filter]);
  useEffect(() => {
    fetchLoanGraphs();
  }, [loanFilter]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <div className="flex">
            <h1 className="mn_hdng">Reports and Analytics</h1>
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={() => setOpen(true)}>
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </div>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Users
                <FormControl>
                  <Select
                    className="select_div"
                    value={filter}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleGraphChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Loan Requests
                <FormControl>
                  <Select
                    className="select_div"
                    value={loanFilter}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleLoanGraphChange}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    <MenuItem value="Daily">Daily</MenuItem>
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Yearly">Yearly</MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Earnings
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Transactions
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphTransactionsData} />
            </div>
          </Grid> */}
        </Grid>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={generateResponsiveStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "20px", color: "#1c6975" }}
          >
            Please select csv to download
          </Typography>
          <Box sx={{
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            gap:"10px",
            marginTop:"10px"
          }}>

          <Button className="btn btn_primary" onClick={downloadCsvUser}>
            <FileDownloadIcon /> User CSV
          </Button>
          <Button className="btn btn_primary" onClick={downloadCsvLoan}>
            <FileDownloadIcon /> Loan CSV
          </Button>
          </Box>
        </Box>
      </Modal>
    </MainContainer>
  );
};

export default Analytics;
