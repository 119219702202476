import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useAddCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
} from "../../services/categories";
import { showError, showToast } from "../../constants/toast";
import { UploadMedia } from "../../utils/mediaUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { isString } from "../../utils/validations";
import Loader from "../../constants/Loader";

const AddCategories = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [AddCategory] = useAddCategoryMutation();
  const [getById] = useLazyGetCategoryByIdQuery();
  const [updateCategory] = useUpdateCategoryMutation();
  const [category, setCategory] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const fetchDetails = async (id: string) => {
    setLoading(true);
    try {
      const res = await getById({
        id: id,
      }).unwrap();
      console.log(res, "res");
      if (res?.statusCode === 200) {
        setImage(res?.data?.image);
        formik.setFieldValue("name", res?.data?.name);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (
      file &&
      file.type &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      showError("Invalid Image Type ");
      return;
    }

    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(2, "Minimum  2 characters are required")
        .max(30, "Maximum 30 character are allowed"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        name: formik.values.name,
        image: image,
      };

      if (!image) {
        setError(true);
        return;
      }
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (id) {
        try {
          const res = await updateCategory({
            id: id,
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Category updated successfully");
            navigate("/manage-categories");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const res = await AddCategory({
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Category added successfully");
            navigate("/manage-categories");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  useEffect(() => {
    if (id) {
      fetchDetails(id);
    }
  }, []);
  return (
    <>
      <MainContainer>
        <Loader loading={loading}/>
        <div className="main_loyout">
          <div className="dashboard">
            {state.page === "edit" ? (
              <h1>Edit Category</h1>
            ) : (
              <h1>Add Category</h1>
            )}
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                            setFileName("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box
                        className="upload_image"
                        sx={{ marginBottom: "4px" }}
                      >
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            // onChange={(
                            //   event: ChangeEvent<HTMLInputElement>
                            // ) => {
                            //   const files = (event.target as HTMLInputElement)
                            //     .files;
                            //   if (files && files[0].type.includes("image")) {
                            //     setFileName(files[0].name);
                            //     setImage(URL.createObjectURL(files[0]));
                            //   } else {
                            //     setAlertType(0);
                            //     setShowAlert(true);
                            //     setAlertMessage(
                            //       "This field only accepts images."
                            //     );
                            //   }
                            // }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !image ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Category Name
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                      inputProps={{
                        maxLength: 30,
                      }}
                      fullWidth
                      placeholder="Category Name"
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCategories;
