import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isString, isValidInput } from "../../utils/validations";
import StyleIcon from "@mui/icons-material/Style";
import {
  useAddTagsMutation,
  useLazyDeleteTagsQuery,
  useLazyGetTagsByIdQuery,
  useLazyGetTagsQuery,
  useUpdateTagsMutation,
} from "../../services/tags";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, Tags } from "../../types/General";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../constants/Loader";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageTags = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [wordId, setWordId] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [deleteTag, setDeleteTag] = useState<boolean>(false);
  const [getTags, { isLoading }] = useLazyGetTagsQuery();
  const [getTagsById] = useLazyGetTagsByIdQuery();
  const [updateTags] = useUpdateTagsMutation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Tags[]>([]);
  const [deleteById] = useLazyDeleteTagsQuery();
  const [addTags] = useAddTagsMutation();
  const [loading, setLoading] = useState(false);
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setWordId("");
    formik.resetForm();
    // formik.setFieldError("message", "");
    formik.setFieldValue("name", "");
  };
  const handleClickOpen = () => {
    setOpen(true);
    // setSelectedId(id);
  };
  const handleEdit = (id: string) => {
    setOpen(true);
    setWordId(id);
    getTagsDetails(id);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(30, "Maximum 30 characters are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        name: formik.values.name,
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (wordId) {
        try {
          const res = await updateTags({
            id: wordId,
            body: encryptedBody,
          }).unwrap();

          if (res?.statusCode === 200) {
            showToast("Tag updated successfully");
            setOpen(false);
            await fetchTags();
            // formik.setFieldValue("name", "");
            formik.resetForm();
            setWordId("");
            setEdit(false);
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const res = await addTags({
            body: encryptedBody,
          }).unwrap();

          if (res?.statusCode === 200) {
            showToast("Tag added successfully");
            setOpen(false);
            await fetchTags();
            // formik.setFieldValue("name", "");
            formik.resetForm();
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  const getTagsDetails = async (id: string) => {
    try {
      const res = await getTagsById({
        id: id,
      }).unwrap();
      if (res?.statusCode === 200) {
        formik.setFieldValue("name", res?.data?.name);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const fetchTags = async () => {
    setLoading(true);
    try {
      const res = await getTags({
        page: page,
        size: 10,
        search: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        const category = res?.data?.category || [];
        setData(res?.data?.category);
        setTotalCount(res?.data?.count);

        if (category?.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false);
    }
  };
  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Tags"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
      console.log("tags");
    }
  }, [userData]);
  useEffect(() => {
    fetchTags();
  }, [searchTerm, debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Tags</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === 1 ? (
                  <Button className="btn btn_primary" onClick={handleClickOpen}>
                    Add Tag
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>

                    <TableCell>Tag Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                {data?.length ? (
                  data?.map((item, index) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {(page - 1) * 10 + index + 1}
                        </TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            size="small"
                            onChange={() => {
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                updateTags,
                                fetchTags
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            {hidePermission?.isEdit || userData?.role === 1 ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => {
                                    handleEdit(item?._id);
                                    setEdit(true);
                                  }}
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete ||
                            userData?.role === 1 ? (
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    setDeleteTag(true);
                                    setSelectedId(item?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <StyleIcon /> No Tags Found
                      </div>
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <WarnModal
        open={deleteTag}
        setOpen={setDeleteTag}
        name="tag"
        handleDelete={() => {
          handleDelete(deleteById, selectedId, fetchTags);
        }}
      />
      <Pagination
        module={data}
        page={page}
        totalPages={totalpages}
        onPageChange={onPageChange}
      />
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">
            {edit ? "Edit Tag" : "Add Tag"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                name="name"
                value={formik.values.name}
                type="text"
                placeholder="Add Tag"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{
                  maxLength: 30,
                }}
                // value={formik.values.multiplier}
                // onBlur={formik.handleBlur}
                // onChange={(val) => {
                //   if (val.target.value === " " || val.target.value === ".") {
                //   } else if (isString(val.target.value)) {
                //     formik.handleChange(val);
                //   }
                // }}
                // helperText={formik.touched.category && formik.errors.category}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageTags;
