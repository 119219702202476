// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTfLEmkGaoTwb_ILLWXgKqNfVhnIYuH_U",
  authDomain: "qfi-car-loan.firebaseapp.com",
  projectId: "qfi-car-loan",
  storageBucket: "qfi-car-loan.appspot.com",
  messagingSenderId: "1002198405025",
  appId: "1:1002198405025:web:f83658f16f29fe168d05e0",
  measurementId: "G-9RBHY0LJ3S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app