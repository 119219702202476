import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate, useParams } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  isNumberPrice,
  isNumberScore,
  isString,
  isValidInput,
} from "../../utils/validations";
import {
  useAddTagsMutation,
  useLazyDeleteTagsQuery,
  useLazyGetTagsByIdQuery,
  useLazyGetTagsQuery,
  useUpdateTagsMutation,
} from "../../services/tags";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, Factorsobj } from "../../types/General";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../constants/Loader";
import WarnModal from "../../components/WarnModal";
import {
  handleDelete,
  handleDeleteFactors,
  updateStatus,
  updateStatusFactors,
} from "../../utils/commonFunctions";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { CreditApi } from "../../services/credit";

const Factors = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const { id } = useParams();
  const [parent, setParent] = useState<string>("");
  const [wordId, setWordId] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [deleteTag, setDeleteTag] = useState<boolean>(false);
  const [getTags, { isLoading }] = useLazyGetTagsQuery();
  const [getTagsById] = useLazyGetTagsByIdQuery();
  const [load, setLoad] = useState<boolean>(false);
  const [updateCriteria] = CreditApi.useUpdateCriteriaMutation();
  const [getCreiteriaByID] = CreditApi.useLazyGetCriteriaByIdQuery();
  const [changeOrderMutation] = CreditApi.useChangeOrderMutation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Factorsobj[]>([]);
  const [deleteById] = CreditApi.useLazyDeleteCriteriaQuery();
  const [addCriteria] = CreditApi.useAddCriteriaMutation();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setWordId("");
    formik.resetForm();
  };
  const handleClickOpen = () => {
    formik.resetForm();
    setOpen(true);
  };
  const handleEdit = (id: string) => {};

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      factorName: "",
      factorScore: "",
    },
    validationSchema: Yup.object({
      factorName: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(30, "Maximum 30 characters are allowed"),
      factorScore: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        range: values.factorName,
        points: values.factorScore,
        ...(wordId ? null : { parent: id }),
      };
      console.log(body, "bodyu");
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      console.log(wordId, "wordId");
      if (wordId) {
        try {
          const res = await updateCriteria({
            id: wordId,
            body: encryptedBody,
          }).unwrap();

          if (res?.statusCode === 200) {
            showToast("Factor updated successfully");
            setOpen(false);
            await getByID(parent);
            formik.resetForm();
            setWordId("");
            setEdit(false);
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const res = await addCriteria({
            body: encryptedBody,
          }).unwrap();

          if (res?.statusCode === 200) {
            showToast("Factor added successfully");
            setOpen(false);
            await getByID(parent);

            formik.resetForm();
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  const handleDelete = async () => {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(`Deleted Successfully`);

        await getByID(parent);
        setSelectedId("");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error, "del");
    }
  };

  const updateStatus = async (status: boolean, id: string) => {
    try {
      const body = {
        isBlocked: status,
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await updateCriteria({
        id: id,
        body: encryptedBody,
      }).unwrap();
      if (res?.statusCode === 200) {
        if (!status) {
          console.log("status: ", status);
          await getByID(parent);
          showToast("Enabled successfully");
        } else {
          await getByID(parent);
          showToast("Disabled successfully");
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getByID = async (id: string) => {
    setLoad(true);
    try {
      const res = await getCreiteriaByID({
        id: id,
        search: debouncedSearchTerm.trim(),
        page: page,
        size: 10,
      }).unwrap();
      if (res?.statusCode === 200) {
        const { count, criteria } = res?.data;
        setData(criteria);
        setTotalCount(count);
        setLoad(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      console.log(error);
      setLoad(false);
    }
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Tags"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleOnDragEnd = async(result: any) => {
    const { destination, source } = result;

    // If there's no destination or the item is dropped in the same place, return
    if (!destination || destination.index === source.index) {
      return;
    }
    console.log(source, "source");
    // Rearrange the data array based on drag result
    const reorderedData = Array.from(data);
    console.log(reorderedData, "reorderDaa");
    const [removed] = reorderedData.splice(source.index, 1);
    reorderedData.splice(destination.index, 0, removed);
    console.log(removed, "removed");
    const updatedDataWithSerials = reorderedData.map((item, index) => ({
      ...item,
      serial: (page - 1) * 10 + index + 1, // Update serial number based on new index
    }));
    console.log(updatedDataWithSerials, "serials");
    let body = {
      criteria: updatedDataWithSerials?.map((item) => {
        return { id: item?._id, serial: item?.serial };
      }),
    };
    console.log(body);

    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await changeOrderMutation({ body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        getByID(id || "");
        // setIsDraggable(!isDraggable);
      }
    } catch (error: any) {
      console.log(error);
    }
    // Update the state with reordered data
    setData(reorderedData);
  };
  const handleDragMovement = () => {
    setIsDraggable(!isDraggable);
  };
  const totalpages = Math.ceil(totalCount / 10);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      // checkPermission();
      console.log("tags");
    }
  }, [userData]);
  useEffect(() => {
    if (id) {
      getByID(id);
      setParent(id);
      formik.resetForm();
    }
  }, [searchTerm, debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Factor </h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role === 1 ? (
                  <Button className="btn btn_primary" onClick={handleClickOpen}>
                    Add Factor
                  </Button>
                ) : null}
                {/* <Button
                  className="btn btn_primary"
                  onClick={handleDragMovement}
                >
                  {isDraggable ? "Save order" : "Change order"}
                </Button> */}
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-credit")}
                >
                  Back
                </Button>
              </Box>
            </Box>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="criteria-list">
                {(provided: any) => (
                  <TableContainer
                    className="table_container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                        <TableCell align="center">Drag</TableCell>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>Factor Name</TableCell>
                          <TableCell>Score</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.length ? (
                          data.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided: any, snapshot: any) => (
                                <TableRow
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableCell align="center">
                                      <IconButton {...provided.dragHandleProps}>
                                        <DragHandleIcon />
                                      </IconButton>
                                    </TableCell>
                                  <TableCell align="center">
                                    {(page - 1) * 10 + index + 1}
                                  </TableCell>
                                  <TableCell>{item?.range}</TableCell>
                                  <TableCell>{item?.points}</TableCell>
                                  <TableCell>
                                    <Switch
                                      size="small"
                                      checked={!item?.isBlocked}
                                      onChange={() =>
                                        updateStatus(
                                          !item?.isBlocked,
                                          item?._id
                                        )
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Box className="table_actions">
                                      {hidePermission?.isEdit ||
                                      userData?.role === 1 ? (
                                        <Tooltip title="Edit">
                                          <IconButton
                                            onClick={() => {
                                              setWordId(item?._id);
                                              setEdit(true);
                                              setOpen(true);
                                              formik.setFieldValue(
                                                "factorName",
                                                item?.range
                                              );
                                              formik.setFieldValue(
                                                "factorScore",
                                                item?.points
                                              );
                                            }}
                                          >
                                            <ModeEditIcon />
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                      {hidePermission?.isDelete ||
                                      userData?.role === 1 ? (
                                        <Tooltip title="Delete">
                                          <IconButton
                                            onClick={() => {
                                              setDeleteTag(true);
                                              setSelectedId(item?._id);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      ) : null}
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={5}
                              sx={{ color: "#051140" }}
                            >
                              No Factors Found
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    {provided.placeholder}
                  </TableContainer>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Card>
      </div>
      <WarnModal
        open={deleteTag}
        setOpen={setDeleteTag}
        name="factor"
        handleDelete={() => {
          handleDelete();
        }}
      />
      <Pagination
        module={data}
        page={page}
        totalPages={totalpages}
        onPageChange={onPageChange}
      />
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">
            {edit ? "Edit Factor" : "Add Factor"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <Typography variant="h6" className="custom_label">
                Factor Name
              </Typography>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                name="factorName"
                value={formik.values.factorName}
                type="text"
                placeholder="Factor Name"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "." ||
                    /^-\d+(\.\d+)?$/.test(val.target.value)
                  ) {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                helperText={
                  formik.touched.factorName && formik.errors.factorName
                }
                inputProps={{
                  maxLength: 30,
                }}
              />

              <Typography
                variant="h6"
                className="custom_label"
                sx={{ marginTop: "10px" }}
              >
                Factor Score
              </Typography>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                name="factorScore"
                value={formik.values.factorScore}
                type="text"
                placeholder="Factor Score"
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(val) => {
                  const inputValue = val.target.value;

                  const numberPattern = /^-?\d*\.?\d*$/;

                  if (numberPattern.test(inputValue)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={
                  formik.touched.factorScore && formik.errors.factorScore
                }
                inputProps={{
                  maxLength: 4,
                }}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default Factors;
