import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddIcon from "@mui/icons-material/Add";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import { showError, showToast } from "../../constants/toast";
import { UploadMedia } from "../../utils/mediaUpload";
import { isString } from "../../utils/validations";
import { serviceStationApi } from "../../services/serviceStation";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, serviceStationResponse } from "../../types/General";
import Loader from "../../constants/Loader";

const AddServiceStation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const {id}=useParams()
  //States
  const [phone, setPhone] = useState<string>("");
  const [loading,setLoading]=useState(false)
  const [image, setImage] = useState<string>("");

  const [data, setData] = useState<serviceStationResponse>();

  //API hooks
  const [addstation] = serviceStationApi.useAddServiceStationMutation();
  const [getStations]=serviceStationApi.useLazyGetStationByIdQuery()
  const [updateStation]=serviceStationApi.useUpdateServiceStationMutation()

  // Form-handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name:data?.name || "",
      phone:data?.phone || "",
      address: data?.address || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(50, "Maximum 50 characters are allowed"),
      phone: Yup.string().required("This field is required").matches(/^(?!000000000)\d{10}$/,"Enter a valid phone number"),
      address: Yup.string()
        .required("This field is required")
        .min(5, "Minimum 5 characters are required")
        .max(50, "Maximum 50 characters are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      if (!image) {
        showError("Image is required");
        return;
      }

      const body = {
        name: values.name,
        image: image,
        phone: values.phone,
        countryCode: phone,
        address: values.address,
        latitude: 40.7128,
        longitude: -74.006,
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if(id){
        const res=await updateStation({
          body:encryptedBody,
          id:id
        }).unwrap()
        if(res?.statusCode===200){
          showToast("Service station updated");
          navigate("/manage-service-stations");
        }
      }else{

        try {
          const res = await addstation({
            body: encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            showToast("Service station added");
            navigate("/manage-service-stations");
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }

    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (
      file &&
      file.type &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      showError("Invalid Image Type ");
      event.target.files=null
      return;
    }

    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      const uploadedImages = res?.data;
      console.log(uploadedImages);
      setImage(uploadedImages);
    } else {
      showError(res?.message);
    }
  
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhone(country?.dialCode);
  };

  const fetchStations=async(id:string)=>{
    setLoading(true)
    try{
      const res=await getStations({
        id:id
      }).unwrap()
      if(res?.statusCode===200){
        
        setData(res?.data)
        setImage(res?.data?.image || "")
        setPhone(res?.data?.countryCode)
        setLoading(false)
      }else{
        setData(undefined)
        setLoading(false)
      }
    }catch(error:any){
      setLoading(false)
      showError(error?.data?.message)
    }
  }

  useEffect(()=>{
    if(id){
      fetchStations(id)
    }
  },[])

  return (
    <>
      <MainContainer>
        <Loader isLoad={loading}/>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{state?.type === "edit" ? "Edit" : "Add"} Service Station</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-service-stations");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview2">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label"> Name </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Name"
                      value={formik.values.name}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{
                        maxLength: 50,
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone number
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      value={phone + formik.values.phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label"> Address </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="address"
                      variant="outlined"
                      fullWidth
                      placeholder="Address"
                      value={formik.values.address}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else{
                          formik.handleChange(val);
                        }
                      }}
                      inputProps={{
                        maxLength: 60,
                      }}
                      onBlur={formik.handleBlur}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddServiceStation;
