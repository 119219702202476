import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import ManageServiceProvider from "./manageAdvertiser";
import AddServiceProvider from "./manageAdvertiser/add";
import SubCategories from "./manageCategories/details";
import ChangePassword from "./auth/changePassword";
import ServiceProviderDetails from "./manageAdvertiser/details";
import ManageAmenities from "./manageAmenities";
import ManageListedProperties from "./manageListedProperties";
import PropertyDetails from "./manageListedProperties/details";
import EditProperties from "./manageListedProperties/edit";
import ManageBookings from "./manageBookings";
import BookingDetails from "./manageBookings/details";
import AddAmenities from "./manageAmenities/add";
import ManageReviews from "./manageReviews";
import ReviewDetails from "./manageReviews/details";
import ManageRevenue from "./revenueManagement";
import ManageChallenges from "./ManageChallenges";
import AddChallenge from "./ManageChallenges/add";
import ChallengeDetails from "./ManageChallenges/details";
import ManageProducts from "./manageProducts";
import AddProducts from "./manageProducts/add";
import AddForm from "./manageForms/add";
import ManageForms from "./manageForms";
import ProductDetails from "./manageProducts/details";
import ManageServiceStations from "./manageServiceStations";
import AddServiceStation from "./manageServiceStations/add";
import ManageBanner from "./manageBanner";
import AddBanner from "./manageBanner/add";
import ManageAdvertisements from "./manageLoans";
import LoanDetails from "./manageLoans/details";
import ManageTestimonials from "./manageTestimonials";
import AddTestimonials from "./manageTestimonials/add";
import TestimonialDetails from "./manageTestimonials/details";
import ManageTags from "./manageTags";
import Settings from "./settings";
import ManageChats from "./manageChats";
import ManageLoanTerm from "./manageLoanTerm";
import AddLoanTerm from "./manageLoanTerm/add";
import AddCustomer from "./users/add";
import EditLoan from "./manageLoans/add";
import ManageInterest from "./manageInterest";
import AddInterest from "./manageInterest/add";
import ManageBanks from "./manageBanks/index";
import AddBankDetails from "./manageBanks/add";
import CreditScore from "./creditScore/index"
import Factors from "./creditScore/Factors";
import PaymentWays from "./paymentways";
import Mode from "./paymentways/Mode";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  AddForm,
  ManageForms,
  ManageUsers,
  ManageBanner,
  AddBanner,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  ManageServiceProvider,
  AddServiceProvider,
  SubCategories,
  ChangePassword,
  ServiceProviderDetails,
  ManageAmenities,
  ManageListedProperties,
  PropertyDetails,
  EditProperties,
  ManageBookings,
  BookingDetails,
  AddAmenities,
  ManageReviews,
  ReviewDetails,
  ManageRevenue,
  ManageChallenges,
  AddChallenge,
  ChallengeDetails,
  ManageProducts,
  AddProducts,
  ProductDetails,
  ManageServiceStations,
  AddServiceStation,
  ManageAdvertisements,
  LoanDetails,
  ManageTestimonials,
  AddTestimonials,
  TestimonialDetails,
  ManageTags,
  Settings,
  ManageChats,
  ManageLoanTerm,
  AddLoanTerm,
  AddCustomer,
  EditLoan,
  ManageInterest,
  AddInterest,
  ManageBanks,
  AddBankDetails,
  CreditScore,
  Factors,
  PaymentWays,
  Mode
};
