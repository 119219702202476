import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { isString, isValidInput } from "../../utils/validations";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import type { DropResult } from "react-beautiful-dnd";
import { showError, showToast } from "../../constants/toast";
import { CommonBody, Criteria, Tags } from "../../types/General";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../constants/Loader";
import WarnModal from "../../components/WarnModal";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { CreditApi } from "../../services/credit";
import { useFormik } from "formik";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreditScore = () => {
  const navigate = useNavigate();
  const userData = useAuth();

  const [getCredit, { isLoading }] = CreditApi.useLazyGetCriteriaQuery();
  const [changeOrderMutation] = CreditApi.useChangeOrderMutation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [load, setLoad] = useState(false);
  const [data, setData] = useState<Criteria[]>([]);
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [criteriaId, setCriteriaId] = useState("");
  const [edit, setEdit] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [deleteCriteria, setDeleteCriteria] = useState<boolean>(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [addCriteria] = CreditApi.useAddCriteriaMutation();
  const [updateCriteria] = CreditApi.useUpdateCriteriaMutation();
  const [deleteById] = CreditApi.useLazyDeleteCriteriaQuery();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  const handleDelete = async () => {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(`Deleted Successfully`);

        await fetchCredit();
        setSelectedId("");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      console.log(error, "del");
    }
  };

  const fetchCredit = async () => {
    setLoad(true);
    try {
      const res = await getCredit({
        page: page,
        size: 10,
        search: debouncedSearchTerm.trim(),
        userType: value === 0 ? 1 : 2,
      }).unwrap();
      if (res?.statusCode === 200) {
        const category = res?.data?.data || [];
        setData(res?.data?.data);
        setTotalCount(res?.data?.count);

        if (category?.length === 0 && page > 1) {
          setPage(page - 1);
        }
        setLoad(false);
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoad(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(40, "Maximum 40 characters are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        criteriaName: formik.values.name,
        userType: value === 0 ? 1 : 2,
      };

      console.log(body, "body");

      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if (criteriaId) {
        try {
          const res = await updateCriteria({
            id: criteriaId,
            body: encryptedBody,
          }).unwrap();

          if (res?.statusCode === 200) {
            showToast("Criteria updated successfully");
            setOpen(false);
            await fetchCredit();
            formik.resetForm();
            setCriteriaId("");
            setEdit(false);
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      } else {
        try {
          const res = await addCriteria({
            body: encryptedBody,
          }).unwrap();

          if (res?.statusCode === 200) {
            showToast("Criteria added successfully");
            setOpen(false);
            await fetchCredit();
            formik.resetForm();
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }
    },
  });

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const totalpages = Math.ceil(totalCount / 10);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    formik.setFieldValue("name", "");
    setCriteriaId("");
    setEdit(false);
  };

  const handleEdit = (id: string) => {
    setOpen(true);
    setCriteriaId(id);
    fetchCredit();
  };

  const handleOnDragEnd = async (result: any) => {
    const { destination, source } = result;

    // If there's no destination or the item is dropped in the same place, return
    if (!destination || destination.index === source.index) {
      return;
    }
    console.log(source, "source");
    // Rearrange the data array based on drag result
    const reorderedData = Array.from(data);
    console.log(reorderedData, "reorderDaa");
    const [removed] = reorderedData.splice(source.index, 1);
    reorderedData.splice(destination.index, 0, removed);
    console.log(removed, "removed");

    // Update the state with reordered data
    const updatedDataWithSerials = reorderedData.map((item, index) => ({
      ...item,
      serial: (page - 1) * 10 + index + 1, // Update serial number based on new index
    }));
    console.log(updatedDataWithSerials, "serials");
    let body = {
      criteria: updatedDataWithSerials?.map((item) => {
        return { id: item?._id, serial: item?.serial };
      }),
    };
    console.log(body);

    try {
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await changeOrderMutation({ body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        fetchCredit();
        // setIsDraggable(!isDraggable);
      }
    } catch (error: any) {
      console.log(error);
    }
    // Update the state with reordered data
    // setData(updatedDataWithSerials);
  };

  const handleDragMovement = async () => {};

  useEffect(() => {
    fetchCredit();
  }, [searchTerm, debouncedSearchTerm, page, value]);

  return (
    <MainContainer>
      <Loader isLoad={load} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Credit Score</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Admin" {...a11yProps(0)} />
                <Tab label="User" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Button
                  className="btn btn_primary"
                  onClick={() => setOpen(true)}
                >
                  Add Criteria for Admin
                </Button>
              </Box>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="criteria-list">
                  {(provided: any) => (
                    <TableContainer
                      className="table_container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Drag</TableCell>
                            <TableCell align="center">S.No</TableCell>

                            <TableCell align="center">Criteria Name</TableCell>
                            <TableCell align="center">Factors</TableCell>
                            <TableCell>Action</TableCell>

                            {/* <TableCell>Status</TableCell> */}
                            {/* <TableCell>Action</TableCell> */}
                          </TableRow>
                        </TableHead>

                        {data?.length ? (
                          data?.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided: any) => (
                                <TableBody
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableRow>
                                    <TableCell align="center">
                                      <IconButton {...provided.dragHandleProps}>
                                        <DragHandleIcon />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                      {(page - 1) * 10 + index + 1}
                                    </TableCell>
                                    <TableCell>{item?.criteriaName}</TableCell>
                                    <TableCell align="center">
                                      <Button
                                        className="btn btn_primary"
                                        onClick={() =>
                                          navigate(
                                            "/manage-credit/factors/" +
                                              item?._id
                                          )
                                        }
                                      >
                                        View Factors
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Box className="table_actions">
                                        <Tooltip title="Edit">
                                          <IconButton
                                            onClick={() => {
                                              setCriteriaId(item?._id);
                                              setEdit(true);
                                              setOpen(true);
                                              formik.setFieldValue(
                                                "name",
                                                item?.criteriaName || ""
                                              );
                                            }}
                                          >
                                            <ModeEditIcon />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete">
                                          <IconButton
                                            onClick={() => {
                                              setDeleteCriteria(true);
                                              setSelectedId(item?._id);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <TableBody>
                            <TableCell
                              align="center"
                              colSpan={10}
                              sx={{ color: "#051140" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <CreditScoreIcon /> No Credit Found
                              </div>
                            </TableCell>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  )}
                </Droppable>
              </DragDropContext>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
                <Button
                  className="btn btn_primary"
                  onClick={() => setOpen(true)}
                >
                  Add Criteria for User
                </Button>
                {/* <Button
                  className="btn btn_primary"
                  onClick={handleDragMovement}
                >
                  {isDraggable ? "Save order" : "Change order"}
                </Button> */}
              </Box>
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="criteria-list">
                  {(provided: any) => (
                    <TableContainer
                      className="table_container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Drag</TableCell>
                            <TableCell align="center">S.No</TableCell>
                            <TableCell align="center">Criteria Name</TableCell>
                            <TableCell align="center">Factors</TableCell>
                            <TableCell>Action</TableCell>
                          </TableRow>
                        </TableHead>

                        {data?.length ? (
                          data.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id}
                              index={index}
                            >
                              {(provided: any) => (
                                <TableBody
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // sx={{
                                  //   backgroundColor: "#f0f0f0", // Highlight the row when draggable
                                  //   cursor: "move", // Change cursor to move
                                  // }}
                                >
                                  <TableRow>
                                    <TableCell align="center">
                                      <IconButton {...provided.dragHandleProps}>
                                        <DragHandleIcon />
                                      </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                      {(page - 1) * 10 + index + 1}
                                    </TableCell>
                                    <TableCell>{item.criteriaName}</TableCell>
                                    <TableCell align="center">
                                      <Button
                                        className="btn btn_primary"
                                        onClick={() =>
                                          navigate(
                                            "/manage-credit/factors/" + item._id
                                          )
                                        }
                                      >
                                        View Factors
                                      </Button>
                                    </TableCell>
                                    <TableCell>
                                      <Box className="table_actions">
                                        <Tooltip title="Edit">
                                          <IconButton
                                            onClick={() => {
                                              setCriteriaId(item._id);
                                              setEdit(true);
                                              setOpen(true);
                                              formik.setFieldValue(
                                                "name",
                                                item?.criteriaName || ""
                                              );
                                            }}
                                          >
                                            <ModeEditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <IconButton
                                            onClick={() => {
                                              setDeleteCriteria(true);
                                              setSelectedId(item._id);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <TableBody>
                            <TableCell
                              align="center"
                              colSpan={10}
                              sx={{ color: "#051140" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <CreditScoreIcon /> No Credit Found
                              </div>
                            </TableCell>
                          </TableBody>
                        )}
                      </Table>
                      {provided.placeholder}
                    </TableContainer>
                  )}
                </Droppable>
              </DragDropContext>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <WarnModal
        open={deleteCriteria}
        setOpen={setDeleteCriteria}
        name="Criteria"
        handleDelete={() => {
          handleDelete();
        }}
      />

      <Pagination
        module={data}
        page={page}
        totalPages={totalpages}
        onPageChange={onPageChange}
      />
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">
            {edit ? "Edit Criteria" : "Add Criteria"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="message"
                name="name"
                value={formik.values.name}
                type="text"
                placeholder={edit ? "Edit Criteria" : "Add Criteria"}
                fullWidth
                onBlur={formik.handleBlur}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.name && formik.errors.name}
                inputProps={{
                  maxLength: 40,
                }}
                // value={formik.values.multiplier}
                // onBlur={formik.handleBlur}
                // onChange={(val) => {
                //   if (val.target.value === " " || val.target.value === ".") {
                //   } else if (isString(val.target.value)) {
                //     formik.handleChange(val);
                //   }
                // }}
                // helperText={formik.touched.category && formik.errors.category}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Save
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default CreditScore;
