import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControl,
  
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
  useAddInterestMutation,
  useLazyDeleteInterestQuery,
  useLazyGetInterestByIdQuery,
  useLazyGetInterestQuery,
  useUpdateInterestMutation,
} from "../../services/interestRate";
import Loader from "../../constants/Loader";
import { showError, showToast } from "../../constants/toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber, isNumberPrice, isValidInput } from "../../utils/validations";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, InterestRate, InterestRateResponse } from "../../types/General";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageInterest = () => {
  //navigate
  const navigate = useNavigate();
  const userData=useAuth()

  // states
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [data, setData] = useState<InterestRate[]>([]);
  const [deleteTag, setDeleteTag] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [wordId, setWordId] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [loading,setLoading]=useState(false)

  //api hooks
  const [getInterest, { isLoading }] = useLazyGetInterestQuery();
  const [addInterest] = useAddInterestMutation();
  const [updateStatusChange]=useUpdateInterestMutation()
  const [deleteById]=useLazyDeleteInterestQuery()
  const [getInterestById]=useLazyGetInterestByIdQuery()
  const [updateInterest]=useUpdateInterestMutation()
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  //Form-handling

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange:false,
    validateOnBlur:false,
    initialValues: {
      rate: "",
    },
    validationSchema: Yup.object({
      rate:Yup.string().required("This field is Required").min(1,"Minimum one character is required")
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        rate: formik.values.rate,
      };
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      if(wordId){
        try {
          const res = await updateInterest({
            id:wordId,
            body: encryptedBody,
          }).unwrap();
          if(res?.statusCode===200){
            showToast("Interest rate updated")
            setOpen(false)
            setWordId("")
            setEdit(false)
            await fetchInterest()
            formik.setFieldValue("rate","")
            
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }

      }else{

        try {
          const res = await addInterest({
            body: encryptedBody,
          }).unwrap();
          if(res?.statusCode===200){
            showToast("Interest rate added")
            setOpen(false)

            await fetchInterest()
            formik.setFieldValue("rate","")
            
          }
        } catch (error: any) {
          showError(error?.data?.message);
        }
      }

    },
  });

  

  

  const handleEdit = (id: string) => {
    setOpen(true);
    setWordId(id);
    // fetchInterestById(id)
  };
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setWordId("")
    formik.setFieldValue("rate","")
    formik.resetForm()
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Interest"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  
  //API calling
  const fetchInterest = async () => {
    setLoading(true)
    try {
      const res = await getInterest({
        size: 10,
        search: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if(res?.statusCode===200){
        const interest=res?.data?.interestRates||[]
        setData(res?.data?.interestRates||[])
        setTotalCount(res?.data?.count)
        if(interest?.length===0 && page>1){
          

          

            setPage(page-1)
          
        }
        setLoading(false)
      }else{
        setData([])
        setLoading(false)
      }
    } catch (error: any) {
      showError(error?.data?.message);
      setLoading(false)
    }
  };

  const fetchInterestById=async(id:string)=>{
    try{
      const res=await getInterestById({
        id:id
      }).unwrap()
      if(res?.statusCode===200){
        formik.setFieldValue("rate",res?.data?.rate ||"")
      }
    }catch(error:any){
      showError(error?.data?.message)
    }
  }

  const totalPages=Math.ceil(totalCount/10)


  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);
  useEffect(() => {
    fetchInterest();
  }, [searchTerm,page,debouncedSearchTerm]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Interest Rate</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || userData?.role===1?(

                <Button className="btn btn_primary" onClick={handleOpen}>
                  Add Interest Rate
                </Button>
                ):null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Interest Rate (p.a)</TableCell>
                    {/* <TableCell>Interest Rate</TableCell> */}
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                  {data?.length?(
                    data?.map((item,index)=>(
                      <TableBody>
                      <TableRow>
                        <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                        <TableCell>{item?.rate}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            checked={!item?.isBlocked}
                            size="small"
                            onChange={() => {
                              updateStatus(
                                item?._id,
                                !item?.isBlocked,
                                updateStatusChange,
                                fetchInterest
                              );
                            }}
                          />
                        </TableCell>
                        <TableCell>
                        <Box className="table_actions">

                          {hidePermission?.isEdit || userData?.role ===1?(

                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                handleEdit(item?._id)
                                setEdit(true);
                                formik.setFieldValue("rate",item?.rate)

                              }}
                            >
                              <ModeEditIcon />
                            </IconButton>
                          </Tooltip>
                          ):null}
                          
                          {hidePermission?.isDelete || userData?.role===1?(

                          <Tooltip title="Delete">
                            <IconButton
                            onClick={()=>{
                              setSelectedId(item?._id)
                              setDeleteTag(true)

                            }} 
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          ):null}
                        </Box>
                      </TableCell>
                       
                      </TableRow>
                    </TableBody>
                    ))
                      
                    
                  ):(
                     <TableBody>
                     <TableCell
                       align="center"
                       colSpan={10}
                       sx={{ color: "#051140" }}
                     >
                        <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >

                    <PaymentsIcon/>   No Interest Found

                  </div>
                     </TableCell>
                   </TableBody>
                  )}
               
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <WarnModal
      name="interest rate"
      open={deleteTag}
      setOpen={setDeleteTag}
      handleDelete={()=>{
        handleDelete(deleteById,selectedId,fetchInterest)
      }}
      />
      <Pagination
      module={data}
      page={page}
      totalPages={totalPages}
      onPageChange={onPageChange}
      />
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="custom_label">
            {edit ? "Edit Interest Rate" : "Add Interest Rate"}
          </Typography>
          <form onSubmit={formik.handleSubmit}>

          <FormControl fullWidth>
            <TextField
            className="text_field"
              hiddenLabel
              autoFocus
              id="rate"
              type="text"
              name="rate"
              value={formik.values.rate}
              placeholder="Interest Rate"
              inputProps={{ maxLength: 5 }}
              fullWidth
              onBlur={formik.handleBlur}
              helperText={formik.touched.rate && formik.errors.rate}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isNumberPrice(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
            />
          </FormControl>
          <Box className="form_btn">
            <Button className="btn btn_primary" type="submit">Save</Button>
            <Button className="btn btn_primary" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageInterest;
