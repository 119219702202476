import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import CategoryIcon from "@mui/icons-material/Category";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLazyDeleteCategoryQuery, useLazyGetCategoryQuery, useUpdateCategoryMutation } from "../../services/categories";
import { showError, showToast } from "../../constants/toast";
import { Category, CommonBody } from "../../types/General";
import { isValidInput } from "../../utils/validations";
import Tooltip from '@mui/material/Tooltip';
import WarnModal from "../../components/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Pagination from "../../components/Pagination";
import { Permissions } from "../../types/User";
import useAuth from "../../hooks/useAuth";
import Loader from "../../constants/Loader";
const ManageCategories = () => {
  const userData=useAuth()
  const navigate = useNavigate();
  const [getCategory, { isLoading }] = useLazyGetCategoryQuery();
  const [deleteById]=useLazyDeleteCategoryQuery()
  const [updateCategory]=useUpdateCategoryMutation()
  const [data, setData] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [hidePermission, setHidePermission] = useState<
  Permissions | null | undefined
>(null);
  
  
  const [selectedId, setSelectedId] = useState("");
 

  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };


  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateCategory({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        res?.data?.isBlocked?showToast("Disabled successfully"):showToast("Enabled successfully")
        // showToast("");
        await fetchCategory();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating category status");
    }
  };

  const fetchCategory = async () => {
    try {
      const res = await getCategory({
        size:10,
        page:page,
        search:debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        const type=res?.data?.data || []
        setData(res?.data?.data || []);
        setTotalCount(res?.data?.count);
        if(type?.length ===0 && page>1){
          setPage(page-1)
        }
      } else {
        setData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  const checkPermission = () => {
    const permission = userData?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Categories"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  const totalpages=Math.ceil(totalCount/10)
  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    fetchCategory();
  }, [page,searchTerm,debouncedSearchTerm]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading}/>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Categories</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar 
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
               />
              <Box className="cards_header_right">
                {hidePermission?.isEdit||userData?.role===1?(

                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-categories/add",{
                    state:{page:"add"}
                  })}
                >
                  Add Category
                </Button>
                ):null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Category Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                {data?.length ? (
                  data?.map((item, index) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{(page - 1) * 10 + index + 1}</TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img src={item?.image} alt="" />
                          </figure>
                        </TableCell>
                        <TableCell align="center">
                          {item?.name || ""}
                        </TableCell>
                        <TableCell>
                        <Switch {...label} checked={!item?.isBlocked} size="small" onChange={()=>{
                            updateUserStatus(item?._id,!item?.isBlocked)
                        }} />
                        </TableCell>
                        <TableCell>
                        <Box className="table_actions">
                          {/* <IconButton>
                            <VisibilityIcon
                              onClick={() =>
                                navigate("/manage-categories/details")
                              }
                            />
                          </IconButton> */}

                          {hidePermission?.isEdit || userData?.role===1?(

                          <Tooltip title="Edit">

                          <IconButton
                            onClick={() => navigate("/manage-categories/edit/"+item?._id,{
                              state:{page:"edit"}
                            })
                              
                          }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          </Tooltip>
                          ):null}

                          {hidePermission?.isDelete || userData?.role===1?(

                          <Tooltip title="Delete">

                          <IconButton onClick={()=>{
                            setOpen(true)
                            setSelectedId(item?._id)
                          }}>
                            <DeleteIcon />
                          </IconButton>
                          </Tooltip>
                          ):null}
                        </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                       <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >

                     <CategoryIcon/> No Category Found
                  </div>
                    </TableCell>
                  </TableBody>
                )}
                
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
      <WarnModal
      open={open}
      setOpen={setOpen}
      name="category"
      handleDelete={()=>{
                handleDelete(deleteById,selectedId,fetchCategory)
      }}
      />
      <Pagination
      module={data}
      page={page}
      totalPages={totalpages}
      onPageChange={onPageChange}
      />
    </MainContainer>
  );
};

export default ManageCategories;
